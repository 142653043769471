



























































































import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import { ChatDialog } from '@/utils/types'

import PathDrawer from '../components/map/PathDrawer.vue'

type LevelData = {
  requiredLevel?: number,
  route?: string,
  top?: string,
  left?: string
  data?: string
}

type PathData = {
  requiredLevel?: number,
  data: string
}

export default Vue.extend({
  name: 'Map',

  components: {
    PathDrawer,
    ChatAvatar
  },

  data () {
    return {
      hideChat: false,
      drawing: true,
      drawInitialLine: false,
      fillHeight: true,
      dialogSequence: 0,
      enabledLevel: 0,
      dialogEntry: 'entry',
      levels: [
        {
          name: this.$t('chapter.welcome'),
          route: 'willkommen',
          top: '88.7%',
          left: '37%',
          textAlign: 'bottom-center',
          dialogs: ['map.level_1']
        },
        {
          name: this.$t('chapter.light-spectrum'),
          route: 'spektrum',
          requiredLevel: 0,
          top: '78.6%',
          left: '72.2%',
          textAlign: 'center-left',
          data: 'M542.8703,1061.5892c0,72.6417-58.8878,131.5295-131.5296,131.5295H285.2766',
          dialogs: ['map.level_2', 'map.level_2b']
        },
        {
          name: this.$t('chapter.comparison'),
          route: 'vergleich',
          requiredLevel: 1,
          top: '59.5%',
          left: '18%',
          textAlign: 'center-right',
          data: 'M135.2766,798.5295v0.0003c0,72.6417,58.8878,131.5295,131.5296,131.5295h144.5346\n' +
            '\tc72.6418,0,131.5296,58.8878,131.5296,131.5295v0.0004',
          dialogs: ['map.level_3', 'map.level_3b']
        },
        {
          name: this.$t('chapter.lantern'),
          route: 'laternen',
          top: '40%',
          left: '84%',
          requiredLevel: 2,
          textAlign: 'center-left',
          data: 'M632,535.4705C632,608.1122,573.1122,667,500.4704,667H266.8062c-72.6418,0-131.5296,58.8878-131.5296,131.5295',
          dialogs: ['map.level_4']
        },
        {
          name: this.$t('chapter.animals'),
          route: 'tiere',
          top: '20%',
          left: '22%',
          requiredLevel: 3,
          textAlign: 'center-right',
          data: 'M165.2766,272.4112c0,72.6418,58.8878,131.5296,131.5296,131.5296l203.6643-0.0001\n' +
            '\tC573.1122,403.9406,632,462.8284,632,535.4702v0.0003',
          dialogs: ['map.level_5']
        },
        {
          name: this.$t('chapter.humans'),
          route: 'menschen',
          top: '10.7%',
          left: '49.5%',
          textAlign: 'top-center',
          requiredLevel: 4,
          data: 'M368.9344,140.8813l-72.1283,0.0001c-72.6418,0-131.5296,58.8878-131.5296,131.5296',
          dialogs: ['map.level_6']
        }
      ]
    }
  },

  computed: {
    ...mapState('level', {
      currentLevel: 'current',
      completedLevel: 'completed',
      completedFeedback: 'feedbackGiven',
      finished: 'finished'
    }),

    pathData (): PathData[] {
      return this.levels.filter((level: LevelData) => !!level.data).map((filtered) => {
        return {
          requiredLevel: filtered.requiredLevel,
          data: filtered.data as string
        }
      })
    },

    computedLevel (): number {
      return this.completedLevel.length > 0 ? Math.max.apply(Math, this.completedLevel) : 0
    },

    completed (): boolean {
      return this.computedLevel === this.levels.filter(level => !!level.requiredLevel || level.requiredLevel === 0).length && this.computedLevel !== 0
    },

    dialogText (): string {
      const completionText = this.completedFeedback ? 'map.completed' : 'map.feedback'

      return this.completed ? completionText : this.levels[this.computedLevel].dialogs[this.dialogSequence]
    },

    dialog (): Record<string, ChatDialog> {
      return {
        entry: {
          key: this.dialogText,
          choices: {
            continue: (this as any).hideChatDialog
          }
        },
        feedback: {
          key: 'map.feedback',
          choices: {
            continue: (this as any).navigateToFeedback
          }
        }
      }
    }
  },

  watch: {
    hideChat (v: boolean) {
      this.changeOverlay(!v)
    }
  },

  mounted () {
    this.setInfoButtonLeft(true)

    this.dialogEntry = this.completed
      ? (!this.completedFeedback ? 'feedback' : '')
      : 'entry'

    if (this.dialogEntry === 'feedback') {
      this.$gtag.event('ask_for_feedback')
    }

    if (this.completedFeedback) {
      this.setTourCompleted()
    }

    this.changeBackground('')

    this.$gtag.screenview({
      screen_name: 'map',
      level: !this.finished ? this.computedLevel : 'completed'
    } as any)

    this.$gtag.event(`map_view_${!this.finished ? this.computedLevel : 'completed'}`)

    if (this.computedLevel === this.currentLevel) {
      return
    }

    this.enabledLevel = this.currentLevel
  },

  methods: {
    ...mapActions({
      changeLevel: 'level/changeLevel',
      changeOverlay: 'level/changeOverlay',
      changeBackground: 'level/changeBackground',
      finishApp: 'level/finishApp'
    }),
    ...mapMutations('level', ['setFeedbackCompleted', 'setTourCompleted']),
    ...mapMutations('core', ['setInfoButtonLeft']),

    onPathDraw () {
      this.enabledLevel = this.currentLevel

      this.drawing = false
    },

    setFinished () {
      this.finishApp(true)
    },

    hideChatDialog () {
      if (this.levels[this.computedLevel].dialogs.length - 1 > this.dialogSequence) {
        this.dialogSequence = this.dialogSequence + 1

        return 'entry'
      }

      this.completed && this.completedFeedback && this.setFinished()

      this.hideChat = true

      this.enabledLevel = this.currentLevel

      this.drawing = false

      if (!this.computedLevel) {
        this.drawing = true

        this.drawInitialLine = true
      }

      if (this.currentLevel < this.computedLevel || !this.computedLevel) {
        this.drawing = true

        this.changeLevel(this.computedLevel)
      }
    },

    navigateToFeedback () {
      this.hideChat = true

      this.$router.replace('/feedback')
    },

    skipFeedback () {
      this.hideChat = true

      this.setFeedbackCompleted(true)
    },

    checkRatio () {
      const svg = this.$refs.svg as HTMLDivElement

      this.fillHeight = (svg.clientHeight / svg.clientWidth) >= (this.$el.clientHeight / this.$el.clientWidth)
    }
  }
})
