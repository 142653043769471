import sfxCues from '@/assets/sounds/sfx.csv'
import sfxFile from '@/assets/sounds/sfx.mp3'
import voCues from '@/assets/sounds/vo.csv'
import voFile from '@/assets/sounds/vo.mp3'
import { VisibilityListener } from '@/utils/visibility-listener'

type AuditionCue = {
  Name: string
  Start: string
  Duration: string
}

const RE_TIME_CODE = /(\d+):(\d{2}\.\d{3})/
const EFFECT_POOL_SIZE = 5

const timeStrToNumber = (val: string): number => {
  const match = RE_TIME_CODE.exec(val)!
  const minutes = parseInt(match[1])
  const seconds = parseFloat(match[2])
  return Math.round((minutes * 60 + seconds) * 1000)
}

type AudioPlayerElement = HTMLAudioElement & {
  _isPlaying?: boolean,
  _endTime?: number
}

class SoundCtor {
  private sprites: Record<string, [number, number]> = {}
  private sfxSprites: Record<string, [number, number]> = {}
  private voAudio: AudioPlayerElement = null!
  private sfxPool: AudioPlayerElement[] = []

  public isPlaybackAllowed = false

  async preload () {
    return new Promise<void>(resolve => {
      const voCuesTypes: AuditionCue[] = voCues as any
      const sfxCuesTyped: AuditionCue[] = sfxCues as any

      this.sprites = voCuesTypes.reduce<Record<string, [number, number]>>((acc, cue) => {
        const start = timeStrToNumber(cue.Start)
        const duration = timeStrToNumber(cue.Duration)
        acc[cue.Name] = [start, duration]
        return acc
      }, {})

      this.sfxSprites = sfxCuesTyped.reduce<Record<string, [number, number]>>((acc, cue) => {
        const start = timeStrToNumber(cue.Start)
        const duration = timeStrToNumber(cue.Duration)
        acc[cue.Name] = [start, duration]
        return acc
      }, {})

      const mediaSession = (navigator as any).mediaSession
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const emptyFn = () => {}
      if (mediaSession) {
        mediaSession.setActionHandler('play', emptyFn)
        mediaSession.setActionHandler('pause', emptyFn)
        mediaSession.setActionHandler('seekbackward', emptyFn)
        mediaSession.setActionHandler('seekforward', emptyFn)
        mediaSession.setActionHandler('previoustrack', emptyFn)
        mediaSession.setActionHandler('nexttrack', emptyFn)
      }

      this.voAudio = this._createAudioElement()

      VisibilityListener.on((value) => {
        if (!value) {
          this.stop()
        }
        /* this._unloadAll()
        } else {
          this._reloadAll()
        } */
      })

      resolve()
    })
  }

  play (spriteId: string) {
    if (!this._hasVO(spriteId)) {
      return
    }

    this._play(this.voAudio, this.sprites[spriteId])
  }

  playEffect (spriteId: string) {
    if (!this._hasSfx(spriteId)) {
      return
    }

    const audio = this._getAvailableAudioElement()

    if (!audio) {
      return
    }

    this._play(audio, this.sfxSprites[spriteId])
  }

  stop () {
    this._stop(this.voAudio)
    this.sfxPool.forEach(this._stop)
  }

  unlock (): Promise<void> {
    return new Promise(resolve => {
      if (this.isPlaybackAllowed) {
        resolve()
        return
      }

      const onCanPlayThrough = () => {
        this.voAudio.play().then(() => {
          this.isPlaybackAllowed = true
          this.voAudio.pause()

          this._triggerAudioContext()

          this.voAudio.removeEventListener('canplaythrough', onCanPlayThrough)

          resolve()
        }, () => {
          this.voAudio.removeEventListener('canplaythrough', onCanPlayThrough)

          resolve()
        })
      }

      this.voAudio.addEventListener('canplaythrough', onCanPlayThrough)

      this.voAudio.src = voFile
      this.voAudio.load()

      for (let i = 0; i < EFFECT_POOL_SIZE; i++) {
        const audio = this._createAudioElement()
        audio.id = `sfx_audio_${i}`
        audio.src = sfxFile
        audio.load()

        this.sfxPool.push(audio)
      }
    })
  }

  private _createAudioElement () {
    const audio = document.createElement('audio')
    audio.preload = 'auto';
    (audio as any).autobuffer = true
    audio.setAttribute('x-webkit-airplay', 'deny') // Disable the iOS control center media widget
    audio.setAttribute('disableremoteplayback', 'true')
    audio.style.display = 'none'
    audio.addEventListener('timeupdate', (e) => this._onTimeUpdate(e))

    return audio
  }

  private _getAvailableAudioElement (): AudioPlayerElement | undefined {
    return this.sfxPool.find(audio => !audio._isPlaying)
  }

  private _hasVO (spriteId: string): boolean {
    return Object.keys(this.sprites).includes(spriteId)
  }

  private _hasSfx (spriteId: string): boolean {
    return Object.keys(this.sfxSprites).includes(spriteId)
  }

  private _play (audio: AudioPlayerElement, sprite: [number, number]) {
    audio.currentTime = sprite[0] / 1000
    audio._endTime = (sprite[0] + sprite[1]) / 1000
    audio._isPlaying = true

    audio.pause()

    window.setTimeout(() => {
      audio.play()
    })
  }

  private _stop (audio: AudioPlayerElement) {
    if (!audio) {
      return
    }

    audio.pause()
    audio._isPlaying = false
    audio._endTime = 0
  }

  private _onTimeUpdate (e: Event) {
    const audio = e.currentTarget as AudioPlayerElement
    if (audio?.currentTime >= (audio._endTime || 0)) {
      this._stop(audio)
    }
  }

  private _unloadAll () {
    this.stop()

    this.voAudio.src = ''
    this.voAudio.load()

    this.sfxPool.forEach(audio => {
      audio.src = ''
      audio.load()
    })
  }

  private _reloadAll () {
    this.voAudio.src = voFile
    this.voAudio.load()

    this.sfxPool.forEach(audio => {
      audio.src = sfxFile
      audio.load()
    })
  }

  private async _triggerAudioContext () {
    try {
      const AudioContext = window.AudioContext || (window as any).webkitAudioContext
      if (!AudioContext) return

      const audioCtx = new AudioContext()
      audioCtx.close()
    } catch (_) {}
  }
}

const Sound = new SoundCtor()

export default Sound
