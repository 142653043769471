

































import Vue from 'vue'

export default Vue.extend({
  props: {
    questionId: {
      type: String,
      default: ''
    },
    stars: {
      type: Number,
      default: 5
    },
    defaultRating: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      rating: this.defaultRating
    }
  },

  computed: {
    computedStars (): number[] {
      return Array.from(Array(this.stars).keys()).map((star, index) => index + 1)
    }
  },

  watch: {
    rating () {
      this.$emit('change', this.rating)
    }
  }
})
