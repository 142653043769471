import { Module } from 'vuex'

import getters from './getters'
import mutations from './mutations'
import state, { CoreState } from './state'

export default {
  namespaced: true,
  state,
  getters,
  mutations
} as Module<CoreState, any>
