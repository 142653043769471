import { onError } from '@apollo/client/link/error'

export const errorLink = onError((errResponse) => {
  const { graphQLErrors, operation, forward } = errResponse

  if (graphQLErrors) {
    console.log(graphQLErrors)
  }

  forward(operation)
})
