export interface CoreState {
  swipeTutorialCompleted: boolean
  voiceOverMuted: boolean
  chatMsPerChar: number
  _infoButtonLeft: boolean
  _preloaderText: string
  _screenSizeWarningDisabled: boolean
}

const coreState: CoreState = {
  swipeTutorialCompleted: false,
  voiceOverMuted: false,
  chatMsPerChar: parseInt(process.env.VUE_APP_CHAT_MS_PER_CHAR || '50'),
  _infoButtonLeft: false,
  _preloaderText: 'preloader.start',
  _screenSizeWarningDisabled: false
}

export default coreState
