























import Vue from 'vue'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import VueSwing, { SwingCardData } from '@/components/shared/VueSwing.vue'
import { shuffle } from '@/utils'
import { ChatDialog } from '@/utils/types'

export default Vue.extend({
  components: {
    VueSwing,
    ChatAvatar
  },

  data () {
    return {
      dialog: {
        entry: {
          key: 'humans.entry',
          choices: {
            lets_go: (this as any).startQuestion
          }
        },
        step1_1: {
          key: 'humans.step_1_1',
          choices: {
            continue: 'step1_2'
          }
        },
        step1_2: {
          key: 'humans.step_1_2',
          choices: {
            continue: 'complete'
          }
        },
        complete: {
          key: 'humans.step_1_3',
          choices: {
            okay: (this as any).changeView
          }
        }
      } as Record<string, ChatDialog>,

      items: shuffle([{
        key: '1',
        correct: true,
        fullWidth: true,
        text: this.$t('question.humans_1_answer_1'),
        img: require('../../assets/images/humans/smartphone.png')
      }, {
        key: '2',
        correct: true,
        fullWidth: true,
        text: this.$t('question.humans_1_answer_2'),
        img: require('../../assets/images/humans/screen.png')
      }, {
        key: '3',
        correct: true,
        fullWidth: true,
        text: this.$t('question.humans_1_answer_3'),
        img: require('../../assets/images/humans/laptop.png')
      }, {
        key: 4,
        fullWidth: true,
        text: this.$t('question.humans_1_answer_4'),
        img: require('../../assets/images/humans/sundown.png')
      }]) as SwingCardData[],
      dialogEntry: 'entry',

      started: false,
      correct: false
    }
  },

  methods: {
    startQuestion () {
      this.started = true
    },
    changeView () {
      this.$emit('change')
    },

    onCompletion () {
      this.correct = true
      this.dialogEntry = 'step1_1'
    }
  }
})
