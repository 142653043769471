import { gql } from '@apollo/client/core'

export const GET_STATUS = gql`query STATUS {
  status {
    code
  }
}`

export const SEND_FEEDBACK = gql`mutation SEND_FEEDBACK (
  $key: String!,
  $useragent: String!,
  $data: [FeedbackInput!]!
) {
  feedback(key: $key, useragent: $useragent, data: $data)
}`
