












import Vue, { Component } from 'vue'
import { mapActions } from 'vuex'

import ComparisonExercise from '@/views/comparison/ComparisonExercise.vue'
import ComparisonIntro from '@/views/comparison/ComparisonIntro.vue'

export default Vue.extend({
  data () {
    return {
      views: [{
        component: ComparisonIntro,
        background: require('@/assets/images/comparison/background.png')
      }, {
        component: ComparisonExercise
      }],
      selected: 0
    }
  },

  computed: {
    selectedView (): Component & { cid: number } {
      return this.views[this.selected].component as any
    }
  },

  watch: {
    selectedView () {
      this.changeBackground(this.views[this.selected].background || '')
    }
  },

  mounted () {
    this.changeBackground(this.views[this.selected].background || '')
  },

  methods: {
    ...mapActions('level', ['completeLevel', 'changeBackground']),
    onChangeView () {
      this.views.length > this.selected + 1 ? this.selected++ : this.completed()
    },
    completed () {
      this.completeLevel(this.$route.meta.level)

      this.$router.replace('/karte')
    }
  }
})
