




































import Vue from 'vue'

import Sound from '@/utils/sound'

export type ImageChoiceData = {
  id: string,
  label?: string,
  correct?: boolean,
  img?: HTMLImageElement,
  top?: string,
  left?: string
}

export default Vue.extend({
  name: 'ImageChoice',

  props: {
    title: {
      type: String,
      default: undefined
    },
    titleId: {
      type: String,
      default: undefined
    },
    items: {
      type: Array as () => ImageChoiceData[],
      default: () => []
    }
  },

  data () {
    return {
      selected: null as ImageChoiceData | null,
      value: '' as string,

      answered: false,

      animatedItem: ''
    }
  },

  computed: {
    titleText (): string | undefined {
      return this.titleId ? this.$t(this.titleId) as string : this.title
    },
    disabled (): boolean {
      return !this.selected
    }
  },

  mounted () {
    if (!this.titleId) {
      return
    }

    setTimeout(() => {
      Sound.play(this.titleId)
    }, 300)
  },

  methods: {
    onSelect (item: ImageChoiceData) {
      if (this.selected) {
        return
      }

      if (item.correct) {
        Sound.playEffect('correct')
        this.selected = item

        this.$emit('change', this.selected)

        window.setTimeout(() => {
          this.onAnswer()
        }, 500)
      } else {
        Sound.playEffect('wrong')

        this.animatedItem = item.id
      }
    },

    onAnswer () {
      this.answered = true

      this.$emit('answer')
    }
  }
})
