
































import Vue from 'vue'
import { mapActions } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import type { ChatDialog } from '@/utils/types'

export default Vue.extend({
  components: {
    ChatAvatar
  },

  data () {
    return {
      hideChat: false,
      dialogEntry: 'entry'
    }
  },

  computed: {
    dialog (): Record<string, ChatDialog> {
      return {
        entry: {
          key: 'light_spectrum.step_3_1',
          choices: {
            continue: (this as any).hideChatAvatar
          }
        },
        step_3_2: {
          key: 'light_spectrum.step_3_2',
          choices: {
            okay: (this as any).changeView
          }
        }
      }
    }
  },

  methods: {
    ...mapActions('level', ['changeOverlay']),
    changeView () {
      this.$emit('change')
    },

    hideChatAvatar () {
      this.hideChat = true

      this.changeOverlay(false)
    },

    onContinue () {
      window.setTimeout(() => {
        this.hideChat = false

        this.dialogEntry = 'step_3_2'
      }, 0)
    }
  }
})
