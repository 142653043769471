import { ActionTree } from 'vuex'

import { ACTIONS, MUTATIONS } from '@/plugins/store/level/consts'
import { LevelState } from '@/plugins/store/level/state'

export default {
  [ACTIONS.CHANGE_LEVEL]: ({ commit }, payload) => {
    commit(MUTATIONS.SET_LEVEL, payload)
  },
  [ACTIONS.COMPLETE_LEVEL]: ({ commit }, payload) => {
    commit(MUTATIONS.SET_COMPLETED, payload)
  },
  [ACTIONS.COMPLETE_FEEDBACK]: ({ commit }, payload) => {
    commit(MUTATIONS.SET_FEEDBACK_COMPLETED, payload)
  },
  [ACTIONS.CHANGE_OVERLAY]: ({ commit }, payload) => {
    commit(MUTATIONS.SET_OVERLAY, payload)
  },
  [ACTIONS.CHANGE_BACKGROUND]: ({ commit }, payload) => {
    commit(MUTATIONS.SET_BACKGROUND, payload)
  },
  [ACTIONS.FINISH_APP]: ({ commit }, payload) => {
    commit(MUTATIONS.SET_FINISHED, payload)
  }
} as ActionTree<any, LevelState>
