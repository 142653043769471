import Vue from 'vue'
import Vuex, { Module, ModuleOptions } from 'vuex'

import { AuthState } from '@/plugins/store/auth/state'
import { CoreState } from '@/plugins/store/core/state'
import { LevelState } from '@/plugins/store/level/state'
import { uuid } from '@/utils'

import storeModuleAuth from './auth'
import storeModuleCore from './core'
import storeModuleLevel from './level'
import { createPersistentStorage } from './persist'
import storeDB from './store-db'

Vue.use(Vuex)

interface RootState {
  _booted: boolean
  _activated: boolean
  uuid: string
  core: CoreState
  auth: AuthState
  level: LevelState
}

const store = new Vuex.Store({
  state: {
    _booted: false,
    _activated: false
  } as RootState,
  mutations: {
    /**
     *  Helper mutation to trigger persistent store update
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    forcePersist: () => {},
    setBooted: (state, payload) => { state._booted = !!payload },
    setActivated: (state, payload) => { state._activated = !!payload }
  },
  actions: {
    initStore: async ({ commit, state }) => {
      for (const key in state) {
        const storageValue = await storeDB.getModuleState(key)
        if (storageValue) {
          store.replaceState({
            ...store.state,
            [key]: { ...((store.state as any)[key] || {}), ...storageValue }
          })
        }
      }

      if (!store.state.auth.uuid) {
        commit('auth/setUuid', uuid())
      }
    },
    async checkDeviceMotionPermission () {
      if (typeof (DeviceMotionEvent) !== 'undefined') {
        if (typeof (DeviceMotionEvent.requestPermission) === 'function') {
          try {
            const response = await DeviceMotionEvent.requestPermission()
            if (response === 'granted') {
              return true
            }
          } catch (_) {}
        } else {
          // Older Android OS versions, no permission required
          return true
        }
      }

      return false
    }
  },
  modules: {
    core: storeModuleCore,
    auth: storeModuleAuth,
    level: storeModuleLevel
  },
  plugins: [createPersistentStorage()]
})

const oldRegisterModule = store.registerModule
store.registerModule = async function <T, S> (path: string | string[], module: Module<T, S>, options?: ModuleOptions) {
  oldRegisterModule.call(this, path as any, module as any, options)

  const storageValue = await storeDB.getModuleState(path as string)
  if (storageValue) {
    const mergedKeyState = {
      ...((store.state as any)[path as any] || {}),
      ...storageValue
    }

    store.replaceState({
      ...store.state,
      [path as string]: mergedKeyState
    })
  }
}

export default store
