














import Vue from 'vue'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import { ChatDialog } from '@/utils/types'

export default Vue.extend({
  components: {
    ChatAvatar
  },

  data () {
    return {
      dialogEntry: 'entry',

      dialog: {
        entry: {
          key: 'humans.step_3_1',
          choices: {
            continue: 'step3_2'
          }
        },
        step3_2: {
          key: 'humans.step_3_2',
          choices: {
            continue: 'step3_3'
          }
        },
        step3_3: {
          key: 'humans.step_3_3',
          choices: {
            continue: 'step3_4'
          }
        },
        step3_4: {
          key: 'humans.step_3_4',
          choices: {
            continue: 'complete'
          }
        },
        complete: {
          key: 'humans.step_3_6',
          choices: {
            okay: (this as any).changeView
          }
        }
      } as Record<string, ChatDialog>
    }
  },

  methods: {
    changeView () {
      this.$emit('change')
    }
  }
})
