















































import Vue from 'vue'
import { mapActions } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import { ChatDialog } from '@/utils/types'

import PanoramaDimmer from '../../components/panorama/PanoramaDimmer.vue'

export default Vue.extend({
  name: 'Panorama',

  components: {
    PanoramaViewer: () => import('../../components/panorama/PanoramaViewer.vue'),
    PanoramaDimmer,
    ChatAvatar
  },

  data () {
    return {
      dialog: {
        entry: {
          key: 'animals.step_5_1',
          choices: {
            continue: (this as any).hideChat
          }
        },
        step5_2: {
          key: 'animals.step_5_2',
          choices: {
            continue: 'step5_3'
          }
        },
        step5_3: {
          key: 'animals.step_5_3',
          choices: {
            continue: 'permission'
          }
        },
        step5_4: {
          key: 'animals.step_5_4',
          choices: {
            continue: 'step5_5'
          }
        },
        step5_5: {
          key: 'animals.step_5_5',
          choices: {
            continue: (this as any).showBackground
          }
        },
        permission: {
          key: 'animals.permission',
          choices: {
            lets_go: (this as any).startViewer
          }
        }
      } as Record<string, ChatDialog>,
      dialogEntry: 'entry',

      dimmerLevel: 0,
      dimmerLevelsChecked: [] as number[],

      loaded: false,
      showChat: true,
      started: false,
      completed: false,
      deviceOrientationPermission: false,

      milkyWay: require('@/assets/images/animals/background_5.png')
    }
  },

  computed: {
    allLevelsChecked (): boolean {
      return this.dimmerLevelsChecked.length >= 3
    }
  },

  watch: {
    showChat (v: boolean) {
      this.changeOverlay(v)
    }
  },

  mounted () {
    this.completedLevel(this.$route.meta.level)
  },

  methods: {
    ...mapActions({
      completedLevel: 'level/completeLevel',
      changeOverlay: 'level/changeOverlay',
      changeBackground: 'level/changeBackground',
      checkDeviceMotionPermission: 'checkDeviceMotionPermission'
    }),
    hideChat () {
      this.showChat = false
    },
    onChange (data: number) {
      this.dimmerLevel = data
      if (!this.dimmerLevelsChecked.includes(data)) {
        this.dimmerLevelsChecked.push(data)
      }
    },
    onComplete () {
      this.dialogEntry = 'step5_4'
      this.dimmerLevelsChecked = []

      this.changeBackground(this.milkyWay)

      this.showChat = true
      this.started = false
    },
    async startViewer () {
      this.deviceOrientationPermission = await this.checkDeviceMotionPermission()
      this.started = true
      this.showChat = false
    },
    onContinue () {
      if (this.completed) {
        this.changeView()

        return
      }

      if (this.started) {
        this.onComplete()

        return
      }

      this.showChat = true

      this.dialogEntry = 'step5_2'
    },
    showBackground () {
      this.showChat = false

      this.completed = true
    },
    changeView () {
      this.$emit('change')
    }
  }
})
