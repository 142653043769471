





















import Vue from 'vue'

import beforeImage from '../../assets/images/comparison/before.png'

export type ScratchEnd = { completed: boolean }

type Position = {
  x: number,
  y: number
}

export default Vue.extend({
  name: 'Scratch',

  props: {
    backgroundImage: {
      type: String,
      required: true
    },
    fillTarget: {
      type: Number,
      default: 50
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      canvas: {} as HTMLCanvasElement,
      context: {} as CanvasRenderingContext2D | null,
      image: null! as HTMLImageElement,
      imageDimensions: null as { width: number, height: number } | null,
      doubleTouchPrevent: false
    }
  },

  mounted () {
    window.setTimeout(this.setupCanvas, 50)
  },

  methods: {
    async setupCanvas () {
      this.canvas = this.$refs.foreground as HTMLCanvasElement
      this.context = this.canvas.getContext('2d')

      this.onResize()
    },

    setCanvasDimensions () {
      const rect = this.$el.getBoundingClientRect()

      this.canvas.width = rect.width
      this.canvas.height = rect.height
    },

    loadImage () {
      this.image = new Image()

      this.image.onload = () => {
        this.drawImage()
      }

      this.image.src = beforeImage
    },

    drawImage () {
      let imgWidth = this.image.naturalWidth
      let imgHeight = this.image.naturalHeight

      const imageRatio = imgHeight / imgWidth
      const canvasRatio = this.canvas.height / this.canvas.width

      if (imageRatio > canvasRatio) { // image's height too big
        imgHeight = imgWidth * canvasRatio
      } else { // image's width too big
        imgWidth = imgHeight / canvasRatio
      }

      this.context?.drawImage(
        this.image,
        (this.image.naturalWidth - imgWidth) * 0.5,
        (this.image.naturalHeight - imgHeight) * 0.5,
        imgWidth,
        imgHeight,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      )
    },

    onResize () {
      this.setCanvasDimensions()

      if (!this.image?.naturalWidth) {
        this.loadImage()
      } else {
        this.drawImage()
      }
    },

    onTouchStart (e: Event) {
      if (!this.enabled) {
        return
      }

      e.preventDefault()

      this.canvas.addEventListener('touchmove', this.onTouchMove)
      this.canvas.addEventListener('mousemove', this.onTouchMove)
      window.addEventListener('touchend', this.onTouchEnd)
      window.addEventListener('mouseup', this.onTouchEnd)

      this.$emit('scratch-start')
    },

    onTouchMove (e: TouchEvent | MouseEvent) {
      e.preventDefault()

      if (e.type.toLowerCase().includes('mouse')) {
        this.drawPath(this.getTouchPosition((e as MouseEvent)))
      } else {
        for (const touch of (e as TouchEvent).touches) {
          this.drawPath(this.getTouchPosition((touch as Touch)))
        }
      }
    },

    drawPath (position: Position) {
      if (!this.context) {
        return
      }

      this.context.beginPath()

      this.context.globalCompositeOperation = 'destination-out'

      const radius = Math.max(30, window.innerWidth / 30)

      this.context.arc(position.x, position.y, radius, 0, Math.PI * 2, false)
      this.context.fill()
    },

    getTouchPosition (touch: Touch | MouseEvent) {
      const rect = this.canvas.getBoundingClientRect()

      return {
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top
      }
    },

    calcErasedArea () {
      if (!this.context) {
        return
      }

      const imageData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
      const dataArray = imageData.data
      const dataCount = dataArray.length

      const filled = dataArray.filter((data) => {
        return data === 0
      }).length

      return Math.round((filled / dataCount) * 100)
    },

    onTouchEnd () {
      this.canvas.removeEventListener('touchmove', this.onTouchMove)
      this.canvas.removeEventListener('mousemove', this.onTouchMove)
      window.removeEventListener('touchend', this.onTouchEnd)
      window.removeEventListener('mouseup', this.onTouchEnd)

      const filledArea = this.calcErasedArea()

      const completed = filledArea && filledArea > this.fillTarget

      completed && this.onCompleted()

      this.$emit('scratch-end', { completed } as ScratchEnd)
    },

    onCompleted () {
      this.context?.clearRect(0, 0, this.canvas.width, this.canvas.height)
    }
  }
})
