const CACHE_IMAGES = [
  {
    left: 'panorama/left_1.jpg',
    right: 'panorama/right_1.jpg',
    top: 'panorama/top_1.jpg',
    bottom: 'panorama/bottom_1.jpg',
    back: 'panorama/back_1.jpg',
    front: 'panorama/front_1.jpg'
  },
  {
    left: 'panorama/left_2.jpg',
    right: 'panorama/right_2.jpg',
    top: 'panorama/top_2.jpg',
    bottom: 'panorama/bottom_1.jpg',
    back: 'panorama/back_2.jpg',
    front: 'panorama/front_2.jpg'
  },
  {
    left: 'panorama/left_3.jpg',
    right: 'panorama/right_3.jpg',
    top: 'panorama/top_3.jpg',
    bottom: 'panorama/bottom_1.jpg',
    back: 'panorama/back_3.jpg',
    front: 'panorama/front_3.jpg'
  }
]

export type TextureMap = {
  left: string,
  right: string,
  top: string,
  bottom: string,
  back: string,
  front: string
}

export const panoramaImages = [] as TextureMap[]

export function panoramaPreload (): Promise<void> {
  return new Promise((resolve) => {
    CACHE_IMAGES.forEach(async (images) => {
      const textureMap = {
        left: await loadImage(images.left),
        right: await loadImage(images.right),
        top: await loadImage(images.top),
        bottom: await loadImage(images.bottom),
        back: await loadImage(images.back),
        front: await loadImage(images.front)
      } as TextureMap

      panoramaImages.push(textureMap)
    })

    resolve()
  })
}

async function loadImage (path: string) {
  const importedImage = await import(`@/assets/images/${path}`)

  return new Promise<string>(resolve => {
    let img = new Image() as any

    img.onload = () => {
      img = null
    }

    img.src = importedImage.default

    resolve(img.src)
    }
  )
}
