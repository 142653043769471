import lottie, { AnimationItem } from 'lottie-web'
import Vue, { CreateElement, VNode } from 'vue'

import { hasOwn } from '@/utils'

type FrameClamp = {
  min: number
  max: number
}

const LAST_TIME_PLAYED: Record<string, number> = {}

export default Vue.extend({
  name: 'LottiePlayer',

  props: {
    fileName: {
      type: String,
      required: true
    },
    speed: {
      type: [String, Number],
      default: 1
    },
    width: {
      type: [String, Number],
      default: -1
    },
    height: {
      type: [String, Number],
      default: -1
    },
    loop: {
      type: Boolean,
      default: true
    },
    autoPlay: {
      type: Boolean,
      default: true
    },
    loopDelayMin: {
      type: Number,
      default: 0
    },
    seekPosition: {
      type: Number,
      default: 0
    },
    loopDelayMax: {
      type: Number,
      default: 0
    },
    framesClamp: {
      type: Object as () => FrameClamp,
      default: () => ({
        min: 0,
        max: 0
      })
    }
  },

  data: () => ({
    name: 'lottie-animation',
    loopTimeout: 0,
    rendererSettings: {
      scaleMode: 'centerCrop',
      clearCanvas: true,
      progressiveLoad: false,
      hideOnTransparent: true
    },
    anim: {} as AnimationItem,
    style: {},
    frames: 0 as number
  }),

  computed: {
    clampedFrames (): FrameClamp {
      return {
        min: this.framesClamp.min,
        max: this.framesClamp.max || this.frames
      }
    }
  },

  watch: {
    seekPosition (val) {
      const value = (val * this.clampedFrames.max / 100) + this.clampedFrames.min

      this.anim.goToAndStop(Math.floor(value), true)
    }
  },

  mounted () {
    this.init().then(() => undefined)
  },

  beforeDestroy () {
    this.anim.stop()

    this.anim.destroy()

    window.clearTimeout(this.loopTimeout)
  },

  methods: {
    async init () {
      const w = parseInt(this.width as string)
      const h = parseInt(this.height as string)

      this.style = {
        width: (w !== -1) ? `${w}px` : '100%',
        height: (h !== -1) ? `${h}px` : '100%',
        overflow: 'hidden',
        margin: '0 auto'
      }

      if (hasOwn(this.anim, 'destroy')) {
        this.anim.destroy()
      }

      this.anim = await this.getAnimation()

      this.$emit('AnimControl', this.anim)

      this.anim.setSpeed(parseInt(this.speed as string))

      if (this.loopDelayMin > 0) {
        this.anim.loop = false
        this.anim.autoplay = false
        this.executeLoop()
      }

      this.frames = this.anim.getDuration(true)

      this.framesClamp.min !== 0 && this.anim.goToAndStop(this.framesClamp.min, true)
    },

    async getAnimation (): Promise<AnimationItem> {
      const data = await import(`@/assets/lottie/${this.fileName}.json`)

      return lottie.loadAnimation({
        container: this.$el,
        renderer: 'svg',
        loop: this.loop,
        autoplay: this.autoPlay && !this.loopDelayMin,
        animationData: hasOwn(data, 'default') ? data.default : data,
        rendererSettings: this.rendererSettings
      })
    },
    getRandomInt (min:number, max:number) {
      min = Math.ceil(min)
      max = Math.floor(max)

      return Math.floor(Math.random() * (max - min)) + min
    },
    executeLoop () {
      this.anim.play()

      const lastTimePlayed = LAST_TIME_PLAYED[this.fileName] || 0
      let nextExecution = this.getRandomInt(this.loopDelayMin, this.loopDelayMax === 0 ? this.loopDelayMin : this.loopDelayMax)
      if (lastTimePlayed) {
        nextExecution -= (Date.now() - lastTimePlayed)
      }

      this.loopTimeout = window.setTimeout(() => {
        LAST_TIME_PLAYED[this.fileName] = Date.now()
        this.anim.stop()
        this.executeLoop()
      }, nextExecution)
    }
  },

  render (createElement: CreateElement): VNode {
    return createElement('div', {
      style: this.style
    })
  }
})
