












































































import Vue from 'vue'
import { mapActions } from 'vuex'

import { TreeSprites } from '@/assets/sprites/tree'
import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import Scala from '@/components/shared/Scala.vue'
import SpritePlayer from '@/components/shared/SpritePlayer.vue'
import { interpolate } from '@/utils'
import type { ChatDialog } from '@/utils/types'

export default Vue.extend({
  components: {
    SpritePlayer,
    ChatAvatar,
    Scala
  },

  data () {
    return {
      started: true,
      hideChat: false,
      buttonEnabled: false,
      dialogEntry: 'entry',
      sprites: TreeSprites,
      position: 0,
      scalaSteps: Array.from(Array(26).keys()).map(step => ({ value: step }))
    }
  },

  computed: {
    dialog (): Record<string, ChatDialog> {
      return {
        entry: {
          key: 'animals.step_4_1',
          choices: {
            continue: 'step4_2'
          }
        },
        step4_2: {
          key: 'animals.step_4_2',
          choices: {
            lets_go: (this as any).startExercise
          }
        },
        step4_3: {
          key: 'animals.step_4_3',
          choices: {
            continue: 'complete'
          }
        },
        complete: {
          key: 'animals.step_4_4',
          choices: {
            okay: (this as any).changeView
          }
        }
      }
    },

    frameNatural (): number {
      return Math.round(parseFloat(interpolate(0, 25, this.position)))
    },
    frameUnnatural (): number {
      return Math.round(parseFloat(interpolate(3, 16, this.position)))
    }
  },

  methods: {
    ...mapActions('level', ['changeOverlay']),

    changeView () {
      this.$emit('change')
    },
    startExercise () {
      this.started = true
      this.hideChat = true

      this.changeOverlay(false)
    },
    onComplete () {
      this.started = false
      this.hideChat = false

      this.dialogEntry = 'step4_3'
    },

    updatePosition (position: number) {
      this.buttonEnabled = true

      this.position = position / 100
    }
  }
})
