<template>
  <div class="panorama-dimmer">
    <div
      class="panorama-dimmer--max"
      @click="selected = selected + 1 <= steps.length ? selected + 1 : selected"
    >
      <svg
        id="Capa_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 607.1"
        xml:space="preserve"
      >
        <path
          d="M218.2,103C152,116.9,98.1,170.6,84.1,236.7c-11.9,56.3,2.8,112.9,40.4,155.1c19.4,21.8,30.6,50.7,30.6,79.2v30
c0,17.8,11.4,33.5,28.3,39l2.3,0.7l0.4,2.4c5.8,34.1,35.9,59.9,70,59.9s64.2-25.7,70-59.9l0.4-2.4l2.3-0.7
c16.9-5.5,28.3-21.2,28.3-39v-30c0-28.6,11.2-57.1,31.4-80.2c28-32,43.5-73.1,43.5-115.7c0-53.2-23.6-103-64.9-136.5
C325.6,104.9,271.3,91.9,218.2,103L218.2,103z M256,581.1c-20.2,0-39.2-13.9-46.2-33.8l-1.9-5.3H304l-1.9,5.3
C295.2,567.2,276.2,581.1,256,581.1z M316,520.1H196c-10.5,0-19-8.5-19-19v-19h158v19C335,511.6,326.5,520.1,316,520.1z
M335.4,460.1H176.6l-0.4-3.6c-3.2-29.1-15.7-57.3-35.3-79.3c-32.9-37-45.7-86.5-35.3-135.9c12.2-57.7,59.3-104.6,117.1-116.7
c46.7-9.8,94.3,1.6,130.6,31.1c36,29.3,56.7,72.8,56.7,119.4c0,37.3-13.5,73.3-38.1,101.3c-20.4,23.3-32.9,51-36.1,80.1L335.4,460.1
z"
        />
        <path d="M15,279.1c-6.1,0-11,4.9-11,11s4.9,11,11,11h30c6.1,0,11-4.9,11-11s-4.9-11-11-11H15z" />
        <path
          d="M263.8,26.5c-2.1-2.1-4.8-3.3-7.8-3.3c-6.1,0-11,4.9-11,11v30c0,3,1.1,5.7,3.3,7.8c2.1,2.1,4.8,3.3,7.8,3.3
c3,0,5.7-1.1,7.8-3.3c2.1-2.1,3.3-4.8,3.3-7.8v-30C267,31.3,265.8,28.5,263.8,26.5z"
        />
        <path d="M467,278.1c-6.6,0-12,5.4-12,12s5.4,12,12,12h30c6.6,0,12-5.4,12-12s-5.4-12-12-12H467z" />
        <path
          d="M67.3,110.3c-2.9,0-5.7,1.1-7.8,3.2c-4.3,4.3-4.3,11.3,0,15.6l21.2,21.2c2.1,2.1,4.8,3.2,7.8,3.2c2.9,0,5.7-1.1,7.8-3.2
c2.1-2.1,3.2-4.8,3.2-7.8s-1.1-5.7-3.2-7.8l-21.2-21.2C73,111.4,70.2,110.3,67.3,110.3z"
        />
        <path
          d="M443.7,109.3c-3.2,0-6.2,1.2-8.5,3.5L414,134c-2.3,2.3-3.5,5.3-3.5,8.5s1.2,6.2,3.5,8.5s5.3,3.5,8.5,3.5s6.2-1.2,8.5-3.5
l21.2-21.2c2.3-2.3,3.5-5.3,3.5-8.5s-1.2-6.2-3.5-8.5l0,0C449.9,110.5,446.9,109.3,443.7,109.3z"
        />
        <path
          d="M332.9,208.3c0.1-0.1,0.2-0.1,0.3-0.2c6.5-3.5,7.7-12.4,2.3-17.5c-20.6-19.4-48.4-31.4-78.9-31.5c-6.1,0-11.5,4.9-11.5,11
s4.9,11,11,11c24.9,0,47.5,9.7,64.3,25.6C323.7,209.8,328.8,210.5,332.9,208.3z"
        />
        <path
          d="M348.7,227l-0.3,0.1c-4.9,2.6-7,8.5-4.9,13.7c4.1,10.5,6.4,22,6.5,34c0,6,4.8,11.1,10.8,11.2c6.2,0.1,11.2-4.8,11.2-11
c0-15-2.9-29.3-8-42.5C361.6,226.6,354.5,224,348.7,227z"
        />
        <path
          d="M96.4,409.4c-2.9,0-5.7,1.1-7.8,3.2l-21.2,21.2c-2.1,2.1-3.2,4.8-3.2,7.8c0,2.9,1.1,5.7,3.2,7.8c2.1,2.1,4.8,3.2,7.8,3.2
c2.9,0,5.7-1.1,7.8-3.2l21.2-21.2c4.3-4.3,4.3-11.3,0-15.6C102.1,410.5,99.3,409.4,96.4,409.4z"
        />
        <path
          d="M414.6,409.4c-3.1,0-6.1,1.2-8.5,3.5c-4.7,4.7-4.7,12.3,0,17l21.2,21.2c4.7,4.7,12.3,4.7,17,0c2.3-2.3,3.5-5.3,3.5-8.5
s-1.2-6.2-3.5-8.5l-21.2-21.2C420.7,410.6,417.7,409.4,414.6,409.4z"
        />
      </svg>
    </div>
    <div class="panorama-dimmer--steps">
      <div
        v-for="step in steps"
        :key="step"
        class="panorama-dimmer--step"
        :class="{selected: selected >= step}"
        @click="selected = step"
      />
    </div>
    <div
      class="panorama-dimmer--min"
      @click="selected = selected - 1 > 0 ? selected - 1 : selected"
    >
      <svg
        id="Capa_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 607.1"
        xml:space="preserve"
      >
        <path
          d="M218.2,103C152,116.9,98.1,170.6,84.1,236.7c-11.9,56.3,2.8,112.9,40.4,155.1c19.4,21.8,30.6,50.7,30.6,79.2v30
c0,17.8,11.4,33.5,28.3,39l2.3,0.7l0.4,2.4c5.8,34.1,35.9,59.9,70,59.9s64.2-25.7,70-59.9l0.4-2.4l2.3-0.7
c16.9-5.5,28.3-21.2,28.3-39v-30c0-28.6,11.2-57.1,31.4-80.2c28-32,43.5-73.1,43.5-115.7c0-53.2-23.6-103-64.9-136.5
C325.6,104.9,271.3,91.9,218.2,103L218.2,103z M256,581.1c-20.2,0-39.2-13.9-46.2-33.8l-1.9-5.3H304l-1.9,5.3
C295.2,567.2,276.2,581.1,256,581.1z M316,520.1H196c-10.5,0-19-8.5-19-19v-19h158v19C335,511.6,326.5,520.1,316,520.1z
M335.4,460.1H176.6l-0.4-3.6c-3.2-29.1-15.7-57.3-35.3-79.3c-32.9-37-45.7-86.5-35.3-135.9c12.2-57.7,59.3-104.6,117.1-116.7
c46.7-9.8,94.3,1.6,130.6,31.1c36,29.3,56.7,72.8,56.7,119.4c0,37.3-13.5,73.3-38.1,101.3c-20.4,23.3-32.9,51-36.1,80.1L335.4,460.1
z"
        />
        <path
          d="M332.9,208.3c0.1-0.1,0.2-0.1,0.3-0.2c6.5-3.5,7.7-12.4,2.3-17.5c-20.6-19.4-48.4-31.4-78.9-31.5c-6.1,0-11.5,4.9-11.5,11
s4.9,11,11,11c24.9,0,47.5,9.7,64.3,25.6C323.7,209.8,328.8,210.5,332.9,208.3z"
        />
        <path
          d="M348.7,227l-0.3,0.1c-4.9,2.6-7,8.5-4.9,13.7c4.1,10.5,6.4,22,6.5,34c0,6,4.8,11.1,10.8,11.2c6.2,0.1,11.2-4.8,11.2-11
c0-15-2.9-29.3-8-42.5C361.6,226.6,354.5,224,348.7,227z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    name: 'PanoramaDimmer',

    data () {
      return {
        steps: [1, 2, 3],
        selected: 3
      }
    },

    watch: {
      selected () {
        this.$emit('change', this.selected)
      }
    },

    mounted () {
      this.$emit('change', this.selected)
    }
  })
</script>

<style lang="scss" scoped>
  @import "src/styles/variables";

  $step-height: 40px;
  $step-width: 8px;
  $lightBulbSize: 35px;

  .panorama-dimmer {
    position: fixed;

    bottom: 25px;
    left: 25px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    padding: 25px;

    background-color: rgba(black, 0.3);

    border-radius: 25px;

    &--steps {
      display: flex;
      flex-direction: column-reverse;

      padding: 15px 0 10px 0;

      .panorama-dimmer--step {
        width: $step-width;
        height: $step-height;

        margin: 2px 0;

        background-color: gray;

        border-radius: 2px;

        &.selected {
          position: relative;

          background-color: $color-blue-light;

          &::before {
            position: absolute;

            top: 50%;
            left: 50%;

            height: 15px;

            box-shadow: 0 0 4px 3px rgba($color-blue-light, 0.5);

            transform: translate(-0%, -50%);

            content: '';
          }
        }
      }
    }

    // TODO Icons hinzufügen WIP

    &--max {
      fill: $color-blue-light;
    }

    &--min {
      fill: #3f566b;
    }

    &--max,
    &--min {
      svg {
        display: block;

        width: $lightBulbSize;
        height: auto;
      }
    }
  }
</style>
