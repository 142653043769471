






















































import Vue from 'vue'
import { mapState } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import { IS_MOBILE, IS_TABLET, IS_TOUCH } from '@/utils/env'
import Sound from '@/utils/sound'
import { ChatDialog } from '@/utils/types'

export default Vue.extend({
  components: {
    ChatAvatar
  },

  data () {
    return {
      isLandscape: false,
      isMobile: IS_MOBILE && !IS_TABLET,
      isTablet: IS_TABLET,

      dialog: {
        landscape: {
          key: 'info.landscape_not_supported'
        },
        desktop: {
          key: 'info.desktop_not_supported'
        }
      } as Record<string, ChatDialog>
    }
  },

  computed: {
    ...mapState({
      isDisabled: '_screenSizeWarningDisabled',
      booted: '_booted'
    })
  },

  watch: {
    isLandscape (v) {
      if (v) {
        Sound.stop()
      }
    }
  },

  mounted () {
    if (!this.isMobile && !this.isTablet) {
      this.loadAndShowQRCode()
    }
  },

  methods: {
    async loadAndShowQRCode () {
      const rawImport = await import('qrcode')
      const { toCanvas } = rawImport
      await toCanvas(this.$refs.qrcodeCanvas as HTMLCanvasElement, location.origin, {
        width: 256,
        margin: 2,
        color: {
          dark: '#071c37',
          light: '#ffffff'
        }
      })
    },

    checkLandscape () {
      this.isLandscape = this.isMobile &&
        (document.documentElement.clientWidth > document.documentElement.clientHeight)
    }
  }
})
