import type { DirectiveOptions } from 'vue'
import { DirectiveBinding } from 'vue/types/options'

function isMouseEvent (event: MouseEvent | TouchEvent): event is MouseEvent {
  return event.type.includes('mouse')
}

function touchX (event: MouseEvent | TouchEvent): number {
  return isMouseEvent(event)
    ? event.clientX
    : event.touches[0].clientX
}

function touchY (event: MouseEvent | TouchEvent): number {
  return isMouseEvent(event)
    ? event.clientY
    : event.touches[0].clientY
}

export type MoveResult = {
  current: { x: number, y: number },
  offset: { x: number, y: number },
  diff: { x: number, y: number }
}

class TouchMoveHandler {
  private _startX = 0
  private _startY = 0
  private _lastX = 0
  private _lastY = 0
  private _lastResult: MoveResult | null = null

  private readonly _boundStart: (e: MouseEvent | TouchEvent) => void
  private readonly _boundMove: (e: MouseEvent | TouchEvent) => void
  private readonly _boundEnd: (e: MouseEvent | TouchEvent) => void

  constructor (private el: HTMLElement, private binding: DirectiveBinding) {
    this._boundStart = this.onTouchStart.bind(this)
    this._boundMove = this.onTouchMove.bind(this)
    this._boundEnd = this.onTouchEnd.bind(this)

    el.addEventListener('touchstart', this._boundStart)
    el.addEventListener('mousedown', this._boundStart)
  }

  onTouchStart (e: MouseEvent | TouchEvent) {
    e.stopPropagation()
    e.preventDefault()

    this.binding.value.start?.(e)

    this._startX = this._lastX = touchX(e)
    this._startY = this._lastY = touchY(e)

    window.addEventListener('touchmove', this._boundMove)
    window.addEventListener('mousemove', this._boundMove)
    window.addEventListener('touchend', this._boundEnd)
    window.addEventListener('touchcancel', this._boundEnd)
    window.addEventListener('mouseup', this._boundEnd)
  }

  onTouchMove (e: MouseEvent | TouchEvent) {
    e.stopPropagation()

    const x = touchX(e)
    const y = touchY(e)

    const offsetX = x - this._startX
    const offsetY = y - this._startY
    const diffX = x - this._lastX
    const diffY = y - this._lastY

    this._lastX = x
    this._lastY = y

    this._lastResult = {
      current: { x, y },
      offset: { x: offsetX, y: offsetY },
      diff: { x: diffX, y: diffY }
    }

    this.binding.value.move(this._lastResult)
  }

  onTouchEnd () {
    window.removeEventListener('touchmove', this._boundMove)
    window.removeEventListener('mousemove', this._boundMove)
    window.removeEventListener('touchend', this._boundEnd)
    window.removeEventListener('touchcancel', this._boundEnd)
    window.removeEventListener('mouseup', this._boundEnd)

    this.binding.value.end?.(this._lastResult)
  }

  destroy () {
    this.el.removeEventListener('touchstart', this._boundStart)
    this.el.removeEventListener('mousedown', this._boundStart)
  }
}

const TouchMove: DirectiveOptions = {
  inserted: (el, binding) => {
    (el as any).__touchMove__ = new TouchMoveHandler(el, binding)
  },
  unbind: (el) => {
    (el as any).__touchMove__.destroy()
  }
}

export default TouchMove
