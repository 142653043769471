






















import Vue from 'vue'

export default Vue.extend({
  name: 'SpritePlayer',
  props: {
    sprites: {
      required: true,
      type: Array as () => string[],
      default: () => []
    },
    frame: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      spritesLoaded: 0
    }
  },

  computed: {
    loadingCompleted (): boolean {
      return this.spritesLoaded >= this.sprites.length
    },
    progress (): number {
      return this.spritesLoaded / (this.sprites.length || 1) * 100
    }
  },

  created () {
    this.preload()
  },

  methods: {
    async preload () {
      const loader = this.sprites.map(this.loadFile)
      await Promise.all(loader)
    },
    loadFile (file: string): Promise<void> {
      return new Promise(resolve => {
        const img = new Image()
        img.onload = () => {
          this.spritesLoaded += 1
          resolve()
        }
        img.src = file
      })
    }
  }
})
