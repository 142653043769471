import Tree01 from './tree01.png'
import Tree02 from './tree02.png'
import Tree03 from './tree03.png'
import Tree04 from './tree04.png'
import Tree05 from './tree05.png'
import Tree06 from './tree06.png'
import Tree07 from './tree07.png'
import Tree08 from './tree08.png'
import Tree09 from './tree09.png'
import Tree10 from './tree10.png'
import Tree11 from './tree11.png'
import Tree12 from './tree12.png'
import Tree13 from './tree13.png'
import Tree14 from './tree14.png'
import Tree15 from './tree15.png'
import Tree16 from './tree16.png'
import Tree17 from './tree17.png'
import Tree18 from './tree18.png'
import Tree19 from './tree19.png'
import Tree20 from './tree20.png'
import Tree21 from './tree21.png'
import Tree22 from './tree22.png'
import Tree23 from './tree23.png'
import Tree24 from './tree24.png'
import Tree25 from './tree25.png'
import Tree26 from './tree26.png'

export const TreeSprites = [
  Tree01,
  Tree02,
  Tree03,
  Tree04,
  Tree05,
  Tree06,
  Tree07,
  Tree08,
  Tree09,
  Tree10,
  Tree11,
  Tree12,
  Tree13,
  Tree14,
  Tree15,
  Tree16,
  Tree17,
  Tree18,
  Tree19,
  Tree20,
  Tree21,
  Tree22,
  Tree23,
  Tree24,
  Tree25,
  Tree26
]
