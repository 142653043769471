














































































import Vue from 'vue'

export default Vue.extend({
  computed: {
    whatsappLink () {
      const content = this.$t('completion.whatsapp_text', [location.origin]) as string
      return `https://api.whatsapp.com/send?text=${encodeURI(content)}`
    }
  },

  methods: {
    openYoutubeLink () {
      this.trackEvent('open_youtube_video')
      window.open('https://www.youtube.com/watch?v=4STE4rYqGZA', '_blank')
    },

    trackEvent (action: string) {
      this.$gtag.event(action)
    }
  }
})
