import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/src/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
      options: {
        customProperties: true
      },
    themes: {
      light: {
        primary: '#ee44aa',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#607d8b',
        secondary: '#795548',
        accent: '#03a9f4',
        error: '#f44336',
        warning: '#ff9800',
        info: '#3f51b5',
        success: '#8bc34a'
      }
    },
    dark: true
  },
    lang: {
      locales: { de },
      current: 'de'
    }
})
