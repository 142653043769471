import { MutationTree } from 'vuex'

import { MUTATIONS } from './consts'
import { CoreState } from './state'

export default {
  [MUTATIONS.SET_SWIPE_TUTORIAL_COMPLETED]: (state) => {
    state.swipeTutorialCompleted = true
  },
  [MUTATIONS.SET_VOICE_OVER_MUTED]: (state, payload?: boolean) => {
    state.voiceOverMuted = !!payload
  },
  [MUTATIONS.TOGGLE_VOICE_OVER_MUTED]: (state) => {
    state.voiceOverMuted = !state.voiceOverMuted
  },
  [MUTATIONS.SET_MS_PER_CHAR]: (state, payload) => {
    state.chatMsPerChar = payload
  },

  [MUTATIONS.SET_PRELOADER_TEXT]: (state, payload) => {
    state._preloaderText = payload
  },

  [MUTATIONS.SET_INFO_BUTTON_LEFT]: (state, payload) => {
    state._infoButtonLeft = !!payload
  },

  [MUTATIONS.ENABLE_SCREEN_SIZE_WARNING]: (state) => {
    state._screenSizeWarningDisabled = false
  },
  [MUTATIONS.DISABLE_SCREEN_SIZE_WARNING]: (state) => {
    state._screenSizeWarningDisabled = true
  }
} as MutationTree<CoreState>
