












































































































































































import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'

import storeDb from '@/plugins/store/store-db'

export default Vue.extend({
  data () {
    return {
      hasDebug: Boolean(process.env.VUE_APP_HAS_DEBUG || false),
      canReset: location.search === '?reset'
    }
  },

  computed: {
    ...mapState('core', ['voiceOverMuted']),

    chapters () {
      return this.$router.getRoutes().filter(route => route.name !== 'dev.prune').map((route) => {
        return {
          name: this.$t(`chapter.${route.name}`),
          route: route.path
        }
      })
    },

    infoButtonLeft () {
      return this.$store.state.core._infoButtonLeft
    },

    speed: {
      get (): number {
        return this.$store.state.core.chatMsPerChar
      },
      set (v: number) {
        this.$store.commit('core/setMsPerChar', v)
      }
    }
  },

  methods: {
    ...mapMutations('core', ['toggleVoiceOverMuted']),

    async resetAppData () {
      await storeDb.prune()

      setTimeout(async () => {
        location.replace('/')
      }, 1000)
    },

    onChapterChange (data: any) {
      const path = data.replace(/\/:[a-z]+\??$/, '')
      this.$router.replace(path).catch(() => {
        console.warn('Duplicated Route')
      })
    }
  }
})
