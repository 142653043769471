































import Vue from 'vue'
import { mapActions } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import { ChatDialog } from '@/utils/types'

export default Vue.extend({
  components: {
    ChatAvatar
  },

  data () {
    return {
      dialog: {
        entry: {
          key: 'lantern.entry',
          choices: {
            continue: 'step1_1'
          }
        },
        step1_1: {
          key: 'lantern.step_1_1',
          choices: {
            continue: (this as any).hideChat
          }
        },
        step1_2: {
          key: 'lantern.step_1_2',
          choices: {
            continue: 'step1_3'
          }
        },
        step1_3: {
          key: 'lantern.step_1_3',
          choices: {
            continue: 'step1_4'
          }
        },
        step1_4: {
          key: 'lantern.step_1_4',
          choices: {
            continue: (this as any).changeView
          }
        }
      } as Record<string, ChatDialog>,
      dialogEntry: 'entry',

      chatHidden: false
    }
  },

  methods: {
    ...mapActions({
      changeOverlay: 'level/changeOverlay'
    }),

    hideChat () {
      this.dialogEntry = ''

      this.chatHidden = true

      this.changeOverlay(false)
    },
    changeView () {
      this.$emit('change')
    },
    onContinue () {
      window.setTimeout(() => {
        this.chatHidden = false

        this.changeOverlay(true)

        this.dialogEntry = 'step1_2'
      }, 0)
    }
  }
})
