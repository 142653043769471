







































































































import Vue from 'vue'
import { mapState } from 'vuex'

import LottiePlayer from '@/components/shared/LottiePlayer'
import store from '@/plugins/store'
import storeDb from '@/plugins/store/store-db'
import Sound from '@/utils/sound'

export default Vue.extend({
  components: {
    LottiePlayer
  },

  data () {
    return {
      dialog: false,
      isSoundAllowed: Sound.isPlaybackAllowed,
      loading: false
    }
  },

  computed: {
    ...mapState({ booted: '_booted' }),
    ...mapState('level', ['welcomeCompleted']),

    text (): string {
      return this.$t(this.$store.state.core._preloaderText) as string
    }
  },

  watch: {
    booted (v: boolean) {
      if (v && this.isSoundAllowed) {
        this.setActivated()
      }
    }
  },

  methods: {
    async activateSound () {
      if (this.loading) {
        return
      }

      this.loading = true
      await Sound.unlock()
      this.setActivated()
    },
    setActivated () {
      store.commit('setActivated', true)
    },

    async resetAppData () {
      await storeDb.prune()

      setTimeout(async () => {
        location.replace('/')
      }, 1000)
    }
  }
})
