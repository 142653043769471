







































import Vue from 'vue'
import { mapActions } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import VueSwing, { SwingCardData } from '@/components/shared/VueSwing.vue'
import { shuffle } from '@/utils'
import type { ChatDialog } from '@/utils/types'

export default Vue.extend({
  components: {
    VueSwing,
    ChatAvatar
  },

  data () {
    return {
      items: shuffle([{
        key: 1,
        correct: true,
        text: this.$t('question.animals_2_answer_1'),
        img: require('../../assets/images/animals/hedgehog_front.svg')
      }, {
        key: 2,
        correct: true,
        text: this.$t('question.animals_2_answer_2'),
        img: require('../../assets/images/animals/flower.svg')
      }, {
        key: 3,
        text: this.$t('question.animals_2_answer_3'),
        img: require('../../assets/images/animals/insect.svg')
      }] as SwingCardData[]),
      dialogEntry: 'entry',

      chatHidden: false,
      started: false,
      correct: false,
      questionBackground: require('@/assets/images/animals/background_2.png')
    }
  },

  computed: {
    dialog (): Record<string, ChatDialog> {
      return {
        entry: {
          key: 'animals.entry',
          choices: {
            continue: 'step1_1'
          }
        },
        step1_1: {
          key: 'animals.step_1_1',
          choices: {
            continue: 'step1_2'
          }
        },
        step1_2: {
          key: 'animals.step_1_2',
          choices: {
            continue: 'step1_3'
          }
        },
        step1_3: {
          key: 'animals.step_1_3',
          choices: {
            continue: 'step1_4'
          }
        },
        step1_4: {
          key: 'animals.step_1_4',
          choices: {
            continue: (this as any).hideChat
          }
        },
        step2_1: {
          key: 'animals.step_2_1',
          choices: {
            continue: 'step2_1b'
          }
        },
        step2_1b: {
          key: 'animals.step_2_1b',
          choices: {
            lets_go: (this as any).startQuestion
          }
        },
        step2_2: {
          key: 'animals.step_2_2',
          choices: {
            continue: 'complete'
          }
        },
        complete: {
          key: 'animals.step_2_3',
          choices: {
            okay: (this as any).changeView
          }
        }
      }
    }
  },

  methods: {
    ...mapActions('level', ['changeBackground', 'changeOverlay']),

    startQuestion () {
      this.started = true
    },

    changeView () {
      this.$emit('change')
    },

    hideChat () {
      this.chatHidden = true

      this.changeOverlay(false)
      this.changeBackground(this.questionBackground)
    },

    onContinue () {
      window.setTimeout(() => {
        this.chatHidden = false

        this.changeOverlay(true)

        this.dialogEntry = 'step2_1'
      }, 0)
    },

    onCompletion () {
      this.correct = true
      this.dialogEntry = 'step2_2'
    }
  }
})
