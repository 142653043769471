import { MutationTree } from 'vuex'

import { MUTATIONS } from '@/plugins/store/level/consts'
import { LevelState } from '@/plugins/store/level/state'

export default {
  [MUTATIONS.SET_WELCOME_COMPLETED]: (state) => {
    state.welcomeCompleted = true
  },
  [MUTATIONS.SET_TOUR_COMPLETED]: (state) => {
    state.tourCompleted = true
  },
  [MUTATIONS.SET_LEVEL]: (state, payload) => {
    state.current = payload
  },
  [MUTATIONS.SET_COMPLETED]: (state, payload) => {
    state.completed.indexOf(payload) === -1 && state.completed.push(payload)
  },
  [MUTATIONS.SET_FEEDBACK_COMPLETED]: (state, payload) => {
    state.feedbackGiven = payload
  },
  [MUTATIONS.SET_OVERLAY]: (state, payload) => {
    state.overlay = payload
  },
  [MUTATIONS.SET_BACKGROUND]: (state, payload) => {
    state.background = payload
  },
  [MUTATIONS.SET_FINISHED]: (state, payload) => {
    state.finished = payload
  }
} as MutationTree<LevelState>
