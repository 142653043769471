import { render, staticRenderFns } from "./LightSpectrumExercise.vue?vue&type=template&id=380753da&"
import script from "./LightSpectrumExercise.vue?vue&type=script&lang=ts&"
export * from "./LightSpectrumExercise.vue?vue&type=script&lang=ts&"
import style0 from "./LightSpectrumExercise.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./LightSpectrumExercise.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
installComponents(component, {VBtn,VFadeTransition,VResponsive})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
