import { render, staticRenderFns } from "./ScreenSizeError.vue?vue&type=template&id=c5a5b546&"
import script from "./ScreenSizeError.vue?vue&type=script&lang=ts&"
export * from "./ScreenSizeError.vue?vue&type=script&lang=ts&"
import style0 from "./ScreenSizeError.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
