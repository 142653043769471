


































































import Vue from 'vue'
import { mapActions } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import Scala from '@/components/shared/Scala.vue'
import { ChatDialog, ScalaValue } from '@/utils/types'

export default Vue.extend({
  components: {
    ChatAvatar,
    Scala
  },

  data () {
    return {
      dialog: {
        entry: {
          key: 'lantern.step_3_1',
          choices: {
            okay: (this as any).hideChat
          }
        },
        step3_2: {
          key: 'lantern.step_3_2',
          choices: {
            okay: (this as any).changeView
          }
        }
      } as Record<string, ChatDialog>,

      exercise: {
        visibility: 0,
        imgLeft: require('../../assets/images/lantern/background_bad.png'),
        imgRight: require('../../assets/images/lantern/background_good.png'),
        completed: false
      },

      dialogEntry: 'entry',

      chatHidden: false,
      buttonEnabled: false
    }
  },

  methods: {
    ...mapActions({
      changeOverlay: 'level/changeOverlay'
    }),

    hideChat () {
      this.chatHidden = true

      this.changeOverlay(false)
    },
    onChange (event: ScalaValue) {
      this.buttonEnabled = true
      this.exercise.visibility = event.position

      if (event.position >= 95 && !this.exercise.completed) {
        this.exercise.completed = true
      }
    },
    changeView () {
      this.$emit('change')
    },
    onContinue () {
      window.setTimeout(() => {
        this.chatHidden = false

        this.changeOverlay(true)

        this.dialogEntry = 'step3_2'
      }, 0)
    }
  }
})
