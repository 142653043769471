import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { MUTATIONS } from '@/plugins/store/core/consts'
import { sleep } from '@/utils'
import { IS_PROD } from '@/utils/env'

import Animals from '../views/Animals.vue'
import Comparison from '../views/Comparison.vue'
import Completion from '../views/Completion.vue'
import Feedback from '../views/Feedback.vue'
import Humans from '../views/Humans.vue'
import Intro from '../views/Intro.vue'
import Lantern from '../views/Lantern.vue'
import LightSpectrum from '../views/LightSpectrum.vue'
import Map from '../views/Map.vue'
import Welcome from '../views/Welcome.vue'
import store from './store/index'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'setup',
    component: Intro
  }, {
    path: '/willkommen',
    name: 'welcome',
    component: Welcome
  }, {
    path: '/feedback',
    name: 'feedback',
    component: Feedback
  }, {
    path: '/karte',
    name: 'map',
    component: Map
  }, {
    path: '/spektrum',
    name: 'light-spectrum',
    component: LightSpectrum,
    meta: {
      level: 1
    }
  }, {
    path: '/vergleich',
    name: 'comparison',
    component: Comparison,
    meta: {
      level: 2
    }
  }, {
    path: '/laternen',
    name: 'lantern',
    component: Lantern,
    meta: {
      level: 3
    }
  }, {
    path: '/tiere/:step?',
    name: 'animals',
    component: Animals,
    props: true,
    meta: {
      level: 4
    }
  }, {
    path: '/menschen',
    name: 'humans',
    component: Humans,
    meta: {
      level: 5
    }
  }, {
    path: '/abschluss',
    name: 'completion',
    component: Completion
  }
]

const router = new VueRouter({
  mode: !IS_PROD ? 'history' : 'abstract',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const level = to.meta?.level
  const name = router.resolve(to.path).resolved.name

  await awaitBoot()

  store.commit(`core/${MUTATIONS.SET_INFO_BUTTON_LEFT}`, false)

  if (!name) {
    store.state.level.completed.length > 0 ? next('/karte') : next()
  }

  if (level) {
    const levelAllowed = levelAvailable(level) || !IS_PROD
    levelAllowed ? next() : next('/karte')
  }

  if (name === 'feedback') {
    const feedbackAllowed = (!store.state.level.feedbackGiven && completed()) || !IS_PROD
    feedbackAllowed ? next() : next('/karte')
  }

  next()
})

const awaitBoot = async () => {
  while (!store.state._booted) {
    await sleep(100)
  }
}

const completed = (): boolean => {
  const levels = routes.filter(route => route.meta?.level)

  return levels.length === store.state.level.completed.length
}

const levelAvailable = (level: number): boolean => {
  return store.state.level.completed.indexOf(level) !== -1 ||
    store.state.level.current === level - 1
}

export default router
