


















import Vue from 'vue'

export default Vue.extend({
  name: 'Imprint',

  data () {
    return {
      imprint: '<b>Angaben gemäß § 5 TMG</b><br>' +
        'Magistrat der Stadt Fulda<br>' +
        'Bonifatiusplatz 1<br>' +
        'Palais Buttlar<br>' +
        '36037 Fulda<br>' +
        '<br><br>' +
        '<b>Vertreten durch:</b><br>' +
        'Tourismus und Kongressmanagement<br>' +
        'Bonifatiusplatz 1<br>' +
        'Palais Buttlar<br>' +
        '36037 Fulda<br><br>' +
        '<br><b>Kontakt:</b><br>' +
        'Tel: +49 661 / 102-1810, -1816<br>' +
        'Fax: +49 661 / 102-2811<br>' +
        'E-Mail: <a href="mailto:tourismus@fulda.de">tourismus@fulda.de</a><br>' +
        '<br><br>' +
        '<b>Umsatzsteuer:</b>' +
        '<br>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br>' +
        'DE112397560 (Stadt Fulda)<br>' +
        '<br><br>' +
        '<b>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</b>' +
        '<br>Redaktion:<br>' +
        'Elisabeth Schrimpf<br>' +
        'Tourismus und Kongressmanagement<br>' +
        'Bonifatiusplatz 1<br>' +
        'Palais Buttlar<br>' +
        '36037 Fulda<br>' +
        '<br>' +
        '<br>' +
        '<br><b>Gewährleistungs- /Haftungsregelung</b><br><br>' +
        'Das Angebot des Dienstes <a href="www.luna-tour.de">www.luna-tour.de</a> steht unter dem Vorbehalt der technischen Verfügbarkeit, insbesondere im Hinblick auf erforderliche Wartungsarbeiten und Ausfälle durch technische Störungen.  <br>' +
        '<br>Bei der Fülle der zu verarbeitenden Informationen sind trotz sorgfältiger Bearbeitung Fehler oder Unvollständigkeiten nicht zu vermeiden. Für die Richtigkeit der im Rahmen des Dienstes abrufbaren Informationen wird deshalb keine Haftung / Gewährleistung übernommen. Eine Haftung für Schäden, die sich aus der Verwendung der in www.tourimus-fulda.de veröffentlichten Inhalte ergeben, ist ausgeschlossen. <br>' +
        'Das Tourismus und Kongressmanagement Fulda übernimmt auch keine Haftung für fremde Inhalte und distanziert sich von allen Inhalten, auf die durch Links oder andere technische Verweise innerhalb des Dienstes Bezug genommen wird. Das Tourismus und Kongressmanagement Fulda ist nicht Urheber solcher Inhalte. Die Inbezugnahme durch Links ist auch nicht Ausdruck einer bestimmten Meinung des Tourismus und Kongressmanagement Fulda zu diesen Inhalten; insbesondere werden solche Inhalte nicht zu eigen gemacht oder gebilligt. Bitte beachten Sie auch, dass das Tourismus und Kongressmanagement Fulda die unter den Links oder technischen Verweisen erreichbaren Inhalte nicht ständig prüfen kann. Die Nutzung von Links oder anderen technischen Verweisen auf Inhalte außerhalb des Dienstes erfolgt auf eigene Verantwortung. <br>' +
        '<br>Für fremde Inhalte, die unter www.luna-tour.de angeboten werden, ist das Tourismus und Kongressmanagement Fulda allenfalls dann verantwortlich, wenn ihre rechtswidrigen oder strafbaren Inhalte positiv bekannt sind und sie diese nicht sperrt oder löscht, obwohl es ihr technisch möglich und zumutbar wäre. Für Hinweise auf entsprechende Inhalte ist das Tourismus und Kongressmanagement Fulda stets dankbar. <br>' +
        '<br>Im übrigen besteht eine Haftung für Schäden, die durch die Nutzung des Dienstes www.luna-tour.de entstehen, nur bei vorsätzlicher oder grob fahrlässiger Verletzung einer wesentlichen vertraglichen Hauptleistungspflicht (Kardinalpflicht) oder falls eine zugesicherte Eigenschaft fehlt. Dies gilt auch, soweit Erfüllungsgehilfen eingesetzt wurden. Für unvorhersehbare und vertragsuntypische Schäden wird nicht gehaftet.<br>' +
        '<br><br><b>Streitschlichtung</b><br><br>' +
        'Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a><br>' +
        '<br>Unsere E-Mail-Adresse finden Sie oben im Impressum.<br>' +
        '<br>Das Tourismus und Kongressmanagement Fulda nimmt nicht an einem freiwilligen Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Trotzdem sind wir verpflichtet, Ihnen die Kontaktdaten der zuständigen Stelle zu nennen: <br>' +
        '<br>Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V.<br>' +
        'Straßburger Straße 8<br>' +
        '77694 Kehl <br>' +
        'Telefon: +49 785179579 40<br>' +
        'Telefax: +49 7851 79579 41<br>' +
        'Internet: <a href=" www.verbraucher-schlichter.de"> www.verbraucher-schlichter.de</a><br>' +
        'E-Mail: <a href="mailto:mail@verbraucher-schlichter.de">mail@verbraucher-schlichter.de</a><br>' +
        '<br><br>' +
        '<b>Haftung für Inhalte</b><br><br>' +
        'Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. <br>' +
        '<br>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. <br>' +
        '<br><b>Haftung für Links</b><br><br>' +
        'Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. <br>' +
        '<br>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen. <br>' +
        'Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. <br>' +
        '<br>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br>'
    }
  }
})
