













































































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

import LottiePlayer from '@/components/shared/LottiePlayer'
import { clamp, raf, sleep } from '@/utils'
import Sound from '@/utils/sound'
import { ChatDialog, ChatDialogChoice } from '@/utils/types'

export default Vue.extend({
  name: 'ChatAvatar',

  components: { LottiePlayer },

  props: {
    dialogs: {
      type: Object as () => Record<string, ChatDialog>,
      default: () => ({})
    },
    entry: {
      type: String,
      default: 'entry'
    },
    left: {
      type: Boolean,
      default: false
    },
    forceOpen: {
      type: Boolean,
      default: false
    }
  },

  data () {
    const entryKey = this.entry || Object.keys(this.dialogs)[0]
    const dialog = this.dialogs?.[entryKey]

    return {
      dialog,
      showBubble: !!dialog,
      lineIndex: -1,
      showAnswers: false,
      waitingForFeedback: false,
      isResizing: false,
      scrollTop: false,
      scrollBottom: false
    }
  },

  computed: {
    ...mapState('core', ['chatMsPerChar']),

    choices (): Record<string, ChatDialogChoice> {
      return this.dialog?.choices || {}
    },
    lines (): string[] {
      if (!this.dialog) {
        return []
      }

      const text = this.$t(`chats.${this.dialog.key}`) as string
      return text
        .replace(/\[list\](.+)\[\/list\]/g, '<ul><li>$1</li></ul>')
        .split('\n')
    },
    currentLine (): string {
      return this.lines[this.lineIndex] || ''
    },
    timeToShowAnswers (): number {
      return this.chatMsPerChar * 100
    },
    timeToNextLine (): number {
      if (this.lineIndex < 0) return 200

      return clamp(
        this.currentLine.length * this.chatMsPerChar,
        this.chatMsPerChar * 30,
        this.chatMsPerChar * 200
      )
    },
    hasAnswers (): boolean {
      return Object.keys(this.choices).length > 0
    }
  },

  watch: {
    entry (v: string) {
      this.dialog = this.dialogs?.[v]

      if (this.dialog) {
        this.showBubble = true
        this.init()
      }
    },
    showBubble (v: boolean) {
      this.changeOverlay(v)
    }
  },

  mounted () {
    if (!this.dialog) return
    this.init()
  },

  methods: {
    ...mapActions({
      changeOverlay: 'level/changeOverlay'
    }),
    init () {
      setTimeout(this.displayNextLine, this.timeToNextLine)
      setTimeout(this.checkScrollable, this.timeToNextLine)
      setTimeout(this.playSound, this.timeToNextLine)
    },
    async playSound () {
      Sound.play(this.dialog?.key || '')
    },
    async displayNextLine () {
      this.lineIndex += 1

      if (this.lineIndex >= this.lines.length - 1) {
        await sleep(this.timeToShowAnswers)

        if (this.hasAnswers) {
          this.showAnswers = true
        } else if (!this.forceOpen) {
          this.showBubble = false
          await sleep(300)
        }

        return
      }

      setTimeout(this.displayNextLine, this.timeToNextLine)
    },

    checkScrollable () {
      const lineContainer: HTMLElement = this.$el.querySelector('.speech-bubble--line-container')!

      const scrollable = lineContainer.scrollHeight !== lineContainer.clientHeight

      if (scrollable) {
        lineContainer.scrollTop = 0

        this.scrollBottom = true

        lineContainer.addEventListener('scroll', this.onScroll)
      } else {
        this.scrollTop = false
        this.scrollBottom = false

        lineContainer.removeEventListener('scroll', this.onScroll)
      }
    },

    onScroll () {
      const lineContainer = this.$el.querySelector('.speech-bubble--line-container')! as HTMLElement

      this.scrollTop = lineContainer.scrollTop > 0
      this.scrollBottom = lineContainer.scrollHeight > lineContainer.clientHeight + lineContainer.scrollTop
    },

    async onChoice (value: ChatDialogChoice) {
      let nextKey = ''

      Sound.stop()

      if (typeof value !== 'string') {
        this.waitingForFeedback = true
        nextKey = await value()
        this.waitingForFeedback = false
      } else {
        nextKey = value
      }

      this.lineIndex = -1
      this.showAnswers = false

      await sleep(300)

      const contentElement: HTMLElement = this.$el.querySelector('.speech-bubble--content')!

      this.isResizing = true

      const oldRect = { width: contentElement.offsetWidth, height: contentElement.offsetHeight }
      this.dialog = this.dialogs[nextKey]

      if (!this.dialog) {
        this.showBubble = false
        return
      }

      await this.$nextTick()

      const newRect = { width: contentElement.offsetWidth, height: contentElement.offsetHeight }

      contentElement.style.width = `${oldRect.width}px`
      contentElement.style.height = `${oldRect.height}px`

      await raf()

      contentElement.style.width = `${newRect.width}px`
      contentElement.style.height = `${newRect.height}px`

      await sleep(300)

      contentElement.style.width = ''
      contentElement.style.height = ''

      this.isResizing = false
      this.scrollTop = false
      this.scrollBottom = false

      this.init()
    }
  }
})
