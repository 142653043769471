

























import Vue from 'vue'
import { mapMutations } from 'vuex'

export default Vue.extend({
  props: {
    questionId: {
      type: String,
      default: ''
    },
    counter: {
      type: Number,
      default: undefined
    },
    label: {
      type: String,
      default: 'give_feedback'
    }
  },

  data () {
    return {
      value: '' as string
    }
  },

  watch: {
    value () {
      this.$emit('change', this.value)
    }
  },

  methods: {
    ...mapMutations('core', [
      'enableScreenSizeWarning',
      'disableScreenSizeWarning'
    ])
  }
})
