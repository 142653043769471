


































































































































































import Vue from 'vue'

type Options = {
  id: string,
  label?: string,
  correct?: boolean,
  img?: HTMLImageElement,
  top?: string,
  left?: string,
  animated: boolean,
  portrait?: boolean
}

export default Vue.extend({
  name: 'Question',

  props: {
    questionId: {
      type: String,
      default: ''
    },
    options: {
      type: Array as () => Array<Options>,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    directFeedback: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    hideInput: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    preventAnimation: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selected: [] as Array<string>,
      value: '' as string,
      answered: false,
      animatedItem: ''
    }
  },

  computed: {
    solution (): Array<string> {
      return this.options.filter((opt) => opt.correct).map((filtered) => filtered.id)
    },
    disabled (): boolean {
      return (this.multiple ? this.selected.length === 0 : !this.value) || this.answered
    },
    correct (): boolean {
      if (this.multiple) {
        return this.solution.length === this.selected.length &&
          this.solution.filter((val) => !this.selected.includes(val)).length === 0
      }

      return this.solution.length === 1 && this.solution.includes(this.value)
    }
  },

  watch: {
    selected () {
      this.$emit('change', {
        value: this.selected,
        correct: this.correct
      })
    },
    value () {
      this.$emit('change', {
        value: this.value,
        correct: this.correct
      })
    }
  },

  methods: {
    onAnswer () {
      this.answered = true

      this.$emit('answer')
    },
    onWrong (id: string) {
      this.animatedItem = id
    }
  }
})
