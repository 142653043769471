import { Store } from 'vuex'

import { debounce } from '@/utils'

import storeDB from './store-db'

const queue: ({ key: string, value: any })[] = []

const update = debounce(async () => {
  for (let i = 0; i < queue.length; i++) {
    const { key, value } = queue[i]
    await storeDB.setModuleState(value, key)
  }
}, 50)

function scheduleUpdate (key: string, value: any) {
  const oldIndex = queue.findIndex(item => item.key === key)
  if (oldIndex > -1) {
    queue.splice(oldIndex, 1)
  }

  queue.push({ key, value })

  update()
}

export function createPersistentStorage (): ((store: Store<any>) => void) {
  return (store: Store<any>) => {
    store.subscribe(async (mutation, state) => {
      const savedState = { ...state }

      for (const key in savedState) {
        if (key.startsWith('_')) continue

        const savedStateForKey = { ...savedState[key] }
        for (const prop in savedStateForKey) {
          if (prop.startsWith('_')) delete savedStateForKey[prop]
        }

        scheduleUpdate(key, { ...savedStateForKey, __module__: key })
      }
    })
  }
}
