




































































































































































































































































































































































































import Vue from 'vue'
import { mapActions } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import Scala from '@/components/shared/Scala.vue'
import { sleep } from '@/utils'
import { colorInterpolation } from '@/utils/color-interpolation'
import { ChatDialog, ScalaValue } from '@/utils/types'

export default Vue.extend({
  components: {
    Scala,
    ChatAvatar
  },

  data () {
    return {
      dialog: {
        entry: {
          key: 'lantern.step_2_1',
          choices: {
            lets_go: (this as any).startExercise
          }
        }
      } as Record<string, ChatDialog>,
      dialogEntry: 'entry',

      started: false,
      completed: false,
      buttonEnabled: false,

      availSvgHeight: 0,

      colors: {
        min: 'fffffb',
        max: 'fadd41'
      },

      currentColor: '#fffffb'
    }
  },

  computed: {
    isAndroid () {
      const userAgent = navigator.userAgent.toLowerCase()
      return /android/.test(userAgent) && /tablet/.test(userAgent)
    }
  },

  methods: {
    ...mapActions({
      changeOverlay: 'level/changeOverlay'
    }),
    async startExercise () {
      this.started = true

      this.changeOverlay(false)

      await this.$nextTick()

      await this.getSvgHeight()
    },
    changeView () {
      this.$emit('change')
    },
    onChange (data: ScalaValue) {
      this.buttonEnabled = true

      this.currentColor = this.getCurrentColor(data.position)
    },
    getCurrentColor (percentage: number) {
      const negateValue = 100 - percentage

      return colorInterpolation(this.colors.min, this.colors.max, negateValue / 100)
    },

    async getSvgHeight () {
      const container = this.$refs.svgContainer as HTMLElement
      let availHeight = 0
      while (!availHeight) {
        availHeight = container.getBoundingClientRect().height
        await sleep(50)
      }
      this.availSvgHeight = availHeight - 5
    }
  }
})
