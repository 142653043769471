export const hasOwn =
  (obj: unknown, key: string): boolean => Object.prototype.hasOwnProperty.call(obj, key)

// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce<T extends any> (func: (...args: T[]) => any, wait: number): (...args: T[]) => void {
  let timeoutID: number

  return <any> function (this:any, ...args: any[]) {
    clearTimeout(timeoutID)
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this

    timeoutID = window.setTimeout(function () {
      func.apply(context, args)
    }, wait)
  }
}

export const shuffle = <T>(array: T[]): T[] => array.sort(() => 0.5 - Math.random())

export function uuid (): string {
  let d = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(36)
  })
}

export const sleep =
  (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms))

export const raf =
  (): Promise<void> => new Promise(
    resolve => requestAnimationFrame(
      () => requestAnimationFrame(
        () => resolve()
      )
    )
  )

export function clamp (value: number, min: number, max: number): number {
  return Math.min(max, Math.max(min, value))
}

export function normalizeOrientation (alpha: number, beta: number, gamma: number): {
  alpha: number, beta: number, gamma: number
} {
  const orientation = parseInt(window.orientation as string)
  alpha -= orientation

  while (alpha < 0) alpha += 360
  while (alpha > 360) alpha -= 360
  if (orientation === 180) {
    return { alpha: alpha, beta: -beta, gamma: -gamma }
  } else if (orientation === 90) {
    return { alpha: alpha, beta: -gamma, gamma: beta }
  } else if (orientation === -90) {
    return { alpha: alpha, beta: gamma, gamma: -beta }
  } else {
    return { alpha: alpha, beta: beta, gamma: gamma }
  }
}

const RE_UNIT = /^([-]?[0-9.]+)([a-zA-Z]+)?$/

function parseUnitValue (v: string | number): { value: number, unit: string } {
  const stringVal = String(v)
  if (!RE_UNIT.test(stringVal)) {
    throw new TypeError('[parseUnitValue] Invalid value')
  }

  const [, value, unit] = RE_UNIT.exec(stringVal)!
  return { value: parseInt(value), unit }
}

export function interpolate (from: string | number, to: string | number, ratio: number): string {
  const vFrom = parseUnitValue(from)
  const vTo = parseUnitValue(to)

  if (vFrom.unit !== vTo.unit) {
    throw new TypeError(`[interpolate] Unit mismatch (got "${vFrom.unit}" and "${vTo.unit}")`)
  }

  return `${vFrom.value + (vTo.value - vFrom.value) * ratio}${vFrom.unit || ''}`
}

export function animateWithFPS (animationFn: () => void, fps = 60, debug = false): () => void {
  const fpsInterval = 1000 / fps
  let then = Date.now()
  let frameCount = 0
  const startTime = then
  let stop = false
  animate()

  function animate () {
    // stop
    if (stop) {
      return
    }

    // request another frame

    requestAnimationFrame(animate)

    // calc elapsed time since last loop

    const now = Date.now()
    const elapsed = now - then

    // if enough time has elapsed, draw the next frame

    if (elapsed > fpsInterval) {
      // Get ready for next frame by setting then=now, but...
      // Also, adjust for fpsInterval not being multiple of 16.67
      then = now - (elapsed % fpsInterval)

      if (debug) {
        const sinceStart = now - startTime
        const currentFps = Math.round(1000 / (sinceStart / ++frameCount) * 100) / 100
        console.log('Elapsed time= ' + Math.round(sinceStart / 1000 * 100) / 100 + ' secs @ ' + currentFps + ' fps.')
      }

      animationFn()
    }
  }

  return () => {
    stop = true
  }
}

export function isTouchEvent (e: MouseEvent | TouchEvent): e is TouchEvent {
  return !e.type.toLowerCase().includes('mouse')
}

export function normalizeTouchEvent (e: MouseEvent | TouchEvent, identifier?: number): MouseEvent | Touch {
  if (isTouchEvent(e)) {
    return identifier
      ? [...Array.from(e.touches)].find(t => t.identifier === identifier)!
      : e.touches[0]
  }

  return e
}
