






















































import Vue from 'vue'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import ImageChoice from '@/components/shared/ImageChoice.vue'
import VueSwing, { SwingCardData } from '@/components/shared/VueSwing.vue'
import { shuffle } from '@/utils'
import type { ChatDialog } from '@/utils/types'

export default Vue.extend({
  components: {
    ChatAvatar,
    ImageChoice,
    VueSwing
  },

  data () {
    return {
      question: {
        text: 'question.comparison_1',
        followedDialog: 'step1_2',
        options: [{
          id: '1',
          label: this.$t('question.comparison_1_answer_1')
        }, {
          id: '2',
          label: this.$t('question.comparison_1_answer_2'),
          correct: true
        }]
      },
      items: shuffle([{
        key: '1',
        correct: true,
        text: this.$t('question.comparison_2_answer_1')
      }, {
        key: '2',
        correct: true,
        text: this.$t('question.comparison_2_answer_2')
      }, {
        key: '3',
        correct: true,
        text: this.$t('question.comparison_2_answer_3')
      }, {
        key: '4',
        correct: true,
        text: this.$t('question.comparison_2_answer_4')
      }, {
        key: '5',
        text: this.$t('question.comparison_2_answer_5')
      }]) as SwingCardData[],
      dialogEntry: 'entry',
      showQuestion: false,
      showSwing: false
    }
  },

  computed: {
    dialog (): Record<string, ChatDialog> {
      return {
        entry: {
          key: 'comparison.entry',
          choices: {
            continue: 'step1_1'
          }
        },
        step1_1: {
          key: 'comparison.step_1_1',
          choices: {
            continue: (this as any).startQuestion
          }
        },
        step1_2: {
          key: 'comparison.step_1_2',
          choices: {
            continue: 'step1_3'
          }
        },
        step1_3: {
          key: 'comparison.step_1_3',
          choices: {
            continue: 'step2_1'
          }
        },
        step2_1: {
          key: 'comparison.step_2_1',
          choices: {
            continue: (this as any).startSwipe
          }
        },
        step2_2: {
          key: 'comparison.step_2_2',
          choices: {
            okay: (this as any).changeView
          }
        }
      }
    }
  },

  methods: {
    startQuestion () {
      this.showQuestion = true
    },
    onCompletion () {
      this.showSwing = false

      this.dialogEntry = 'step2_2'
    },
    startSwipe () {
      this.showQuestion = false

      this.showSwing = true
    },
    changeView () {
      this.$emit('change')
    }
  }
})
