export interface AuthState {
  uuid: string,
  token: string
}

const authState: AuthState = {
  uuid: '',
  token: ''
}

export default authState
