import { MutationTree } from 'vuex'

import { MUTATIONS } from '@/plugins/store/auth/consts'
import { AuthState } from '@/plugins/store/auth/state'

export default {
  [MUTATIONS.SET_UUID]: (state, payload) => {
    state.uuid = payload
  },
  [MUTATIONS.SET_ACCESS_TOKEN]: (state, payload) => {
    state.token = payload
  }
} as MutationTree<AuthState>
