import Vue from 'vue'
import VueGTag from 'vue-gtag'

import router from '@/plugins/router'

Vue.use(VueGTag, {
  config: { id: 'G-S5QRTE6EQW' },
  appName: process.env.VUE_APP_TITLE,
  pageTrackerScreenviewEnabled: true,
  pageTrackerExcludedRoutes: ['map', 'feedback'],
  enabled: true
}, router)
