










































import Vue from 'vue'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import ImageChoice from '@/components/shared/ImageChoice.vue'
import type { ChatDialog } from '@/utils/types'

export default Vue.extend({
  components: {
    ChatAvatar,
    ImageChoice
  },

  data () {
    return {
      options: [{
        id: '1',
        label: 1,
        top: '20%',
        left: '25%'
      }, {
        id: '2',
        correct: true,
        label: 4,
        top: '80%',
        left: '45%'
      }, {
        id: '3',
        label: 10,
        top: '48%',
        left: '80%'
      }],
      dialogEntry: 'entry',
      started: false
    }
  },

  computed: {
    dialog (): Record<string, ChatDialog> {
      return {
        entry: {
          key: 'light_spectrum.entry',
          choices: {
            lets_go: (this as any).startQuestion
          }
        },
        step1_2: {
          key: 'light_spectrum.step_1_1',
          choices: {
            continue: 'complete'
          }
        },
        complete: {
          key: 'light_spectrum.step_1_2',
          choices: {
            lets_go: (this as any).changeView
          }
        }
      }
    }
  },

  methods: {
    startQuestion () {
      this.started = true
    },
    changeView () {
      this.$emit('change')
    }
  }
})
