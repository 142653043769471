


































































import Vue from 'vue'
import { mapActions } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import Scala from '@/components/shared/Scala.vue'
import { ChatDialog, ScalaValue } from '@/utils/types'

type Exercises = {
  visibility: number,
  imgLeft: HTMLImageElement,
  imgRight: HTMLImageElement,
  completed: boolean
}

export default Vue.extend({
  components: { ChatAvatar, Scala },

  data () {
    return {
      dialog: {
        entry: {
          key: 'humans.step_2_1',
          choices: {
            lets_go: (this as any).startComparing
          }
        }
      } as Record<string, ChatDialog>,

      started: false,
      completed: false,
      buttonEnabled: false,
      dialogEntry: 'entry',

      exercise: {
        visibility: 100,
        imgLeft: require('../../assets/images/humans/before.png'),
        imgRight: require('../../assets/images/humans/today.png'),
        completed: false
      } as Exercises
    }
  },

  methods: {
    ...mapActions({
      completedLevel: 'level/completeLevel',
      changeOverlay: 'level/changeOverlay'
    }),
    startComparing () {
      this.started = true

      this.changeOverlay(false)
    },
    onChange (event: ScalaValue) {
      this.buttonEnabled = true

      this.exercise.visibility = 100 - event.position

      if (event.position >= 95 && !this.exercise.completed) {
        this.exercise.completed = true
      }
    },
    changeView () {
      this.$emit('change')
    }
  }
})
