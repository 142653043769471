import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client/core'
import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import { createUploadLink } from 'apollo-upload-client'
import Vue from 'vue'
import VueApollo from 'vue-apollo'

import axios from '@/plugins/axios'

import { errorLink } from './error-link'
import PusherLink from './pusher-link'

Vue.use(VueApollo)

export const apolloCache = new InMemoryCache()

const uploadLink = createUploadLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP_ENDPOINT || 'http://localhost:7001/graphql',
  credentials: 'same-origin',
  fetch: buildAxiosFetch(axios, ((config: any, input: any, init: any) => ({
    ...config,
    onUploadProgress: init.onUploadProgress,
    cancelToken: init.cancelToken
  })) as any) as any
})

const echoLink = new PusherLink()

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, echoLink, uploadLink as any]),
  cache: apolloCache,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    }
  },
  connectToDevTools: true
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient as any
})
