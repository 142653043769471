export const ACTIONS = <const>{
  CHANGE_LEVEL: 'changeLevel',
  COMPLETE_LEVEL: 'completeLevel',
  COMPLETE_FEEDBACK: 'completeFeedback',
  CHANGE_OVERLAY: 'changeOverlay',
  CHANGE_BACKGROUND: 'changeBackground',
  FINISH_APP: 'finishApp'
}

export const MUTATIONS = {
  SET_WELCOME_COMPLETED: 'setWelcomeCompleted',
  SET_TOUR_COMPLETED: 'setTourCompleted',
  SET_LEVEL: 'setLevel',
  SET_COMPLETED: 'setCompleted',
  SET_FEEDBACK_COMPLETED: 'setFeedbackCompleted',
  SET_OVERLAY: 'setOverlay',
  SET_BACKGROUND: 'setBackground',
  SET_FINISHED: 'setFinished'
}
