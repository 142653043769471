















































































































































































































































































































































































































































import Vue from 'vue'
import { mapMutations } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import SvgWrapper from '@/components/shared/SvgWrapper.vue'
// import Sound from '@/utils/sound'
import type { ChatDialog } from '@/utils/types'

export default Vue.extend({
  components: {
    ChatAvatar,
    SvgWrapper
  },

  data () {
    return {
      started: false,
      hideChat: false,
      dialogEntry: 'entry',
      completeCount: 4,
      selected: [] as string[],
      showHint: false,
      hintTimeoutId: null! as number
    }
  },

  computed: {
    dialog (): Record<string, ChatDialog> {
      return {
        entry: {
          key: 'animals.step_3_2',
          choices: {
            continue: 'step3_3'
          }
        },
        step3_3: {
          key: 'animals.step_3_3',
          choices: {
            lets_go: (this as any).startExercise
          }
        },
        complete: {
          key: 'animals.step_3_4',
          choices: {
            okay: (this as any).changeView
          }
        }
      }
    }
  },

  beforeDestroy () {
    window.clearTimeout(this.hintTimeoutId)
  },

  methods: {
    ...mapMutations('level', ['setOverlay']),
    startExercise () {
      this.started = true
      this.hideChat = true

      this.startHintTimeout()

      this.setOverlay(false)
    },

    startHintTimeout () {
      this.hintTimeoutId = window.setTimeout(() => {
        this.showHint = true
      }, 20000)
    },

    changeView () {
      this.$emit('change')
    },
    onSelect (id: string) {
      if (this.selected.includes(id)) {
        return
      }

      this.selected.push(id)

      // Sound.playEffect('correct')

      if (this.selected.length === this.completeCount) {
        window.setTimeout(() => {
          this.hideChat = false

          this.dialogEntry = 'complete'
        }, 1000)
      }
    }
  }
})
