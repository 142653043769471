








import Vue, { Component } from "vue";
import { mapActions } from "vuex";

import AnimalInsects from "@/views/animals/AnimalInsects.vue";
import AnimalSearch from "@/views/animals/AnimalSearch.vue";
import AnimalTree from "@/views/animals/AnimalTree.vue";
import PanoramaExercise from "@/views/animals/PanoramaExercise.vue";

export default Vue.extend({
  props: {
    step: {
      type: [Number, String],
      default: 0
    }
  },

  data() {
    return {
      views: [
        {
          component: AnimalInsects,
          background: require("@/assets/images/animals/background_1.png")
        },
        {
          component: AnimalSearch,
          background: require("@/assets/images/animals/background_1.png")
        },
        {
          component: AnimalTree,
          background: require("@/assets/images/animals/background_1.png")
        },
        {
          component: PanoramaExercise,
          background: require("@/assets/images/animals/background_4.png")
        }
      ],
      selected: parseInt(String(this.step))
    };
  },

  computed: {
    selectedView(): Component {
      return this.views[this.selected].component;
    }
  },

  watch: {
    selectedView() {
      this.changeBackground(this.views[this.selected].background || "");
    }
  },

  mounted() {
    this.changeBackground(this.views[this.selected].background || "");
  },

  methods: {
    ...mapActions("level", ["completeLevel", "changeBackground"]),
    onChangeView() {
      this.views.length > this.selected + 1
        ? this.selected++
        : this.completed();
    },
    completed() {
      this.completeLevel(this.$route.meta!.level);

      this.$router.replace("/karte");
    }
  }
});
