






















import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'

import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import type { SwingCardData } from '@/components/shared/VueSwing.vue'
import VueSwing from '@/components/shared/VueSwing.vue'
import { shuffle } from '@/utils'
import type { ChatDialog } from '@/utils/types'

export default Vue.extend({
  name: 'Welcome',

  components: { VueSwing, ChatAvatar },

  data () {
    const cardData = [{
      key: 1,
      img: require('../assets/images/welcome/sun.svg'),
      text: this.$t('question.welcome_answer_3')
    }, {
      key: 2,
      correct: true,
      img: require('../assets/images/welcome/moon.svg'),
      text: this.$t('question.welcome_answer_1')
    }, {
      key: 3,
      correct: true,
      img: require('../assets/images/welcome/star.svg'),
      text: this.$t('question.welcome_answer_2')
    }, {
      key: 4,
      correct: true,
      img: require('../assets/images/welcome/lantern.svg'),
      text: this.$t('question.welcome_answer_4')
    }, {
      key: 5,
      correct: true,
      img: require('../assets/images/welcome/light-bulb.svg'),
      text: this.$t('question.welcome_answer_5')
    }] as SwingCardData[]

    return {
      items: shuffle(cardData),
      dialogEntry: 'entry',
      started: false,
      correct: false,
      firstBackground: require('@/assets/images/welcome/background_1.png'),
      secondBackground: require('@/assets/images/welcome/background_2.png')
    }
  },

  computed: {
    dialog (): Record<string, ChatDialog> {
      return {
        entry: {
          key: 'welcome.entry',
          choices: {
            continue: 'step_1_1'
          },
          sprite: '00_00'
        },
        step_1_1: {
          key: 'welcome.step_1_1',
          choices: {
            okay: (this as any).startQuestion
          },
          sprite: '00_01'
        },
        step_2_1: {
          key: 'welcome.step_2_1',
          choices: {
            continue: 'step_2_2'
          },
          sprite: '00_02'
        },
        step_2_2: {
          key: 'welcome.step_2_2',
          choices: {
            continue: 'step_2_3'
          },
          sprite: '00_02'
        },
        step_2_3: {
          key: 'welcome.step_2_3',
          choices: {
            continue: 'step_2_4'
          },
          sprite: '00_03'
        },
        step_2_4: {
          key: 'welcome.step_2_4',
          choices: {
            lets_go: (this as any).nextSite
          },
          sprite: '00_04'
        }
      }
    }
  },

  mounted () {
    this.changeBackground(this.firstBackground)
  },

  methods: {
    ...mapActions('level', ['changeBackground']),
    ...mapMutations('level', ['setWelcomeCompleted']),

    startQuestion () {
      this.started = true
    },
    nextSite () {
      this.setWelcomeCompleted()
      this.$router.replace('/karte')
    },
    onCompleted () {
      this.dialogEntry = 'step_2_1'

      this.changeBackground(this.secondBackground)

      this.started = false
    }
  }
})
