






















import Vue from 'vue'

export default Vue.extend({
  name: 'PathDrawer',

  props: {
    pathData: {
      type: String,
      required: true
    },
    strokeWidth: {
      type: Number,
      default: 10
    },
    strokeColor: {
      type: String,
      default: '#000000'
    },
    drawPath: {
      type: Boolean,
      default: false
    },
    static: {
      type: Boolean,
      default: false
    },
    offsetPercent: {
      type: Number,
      default: -1
    },
    duration: {
      type: String,
      default: '2000ms'
    },
    easingFunction: {
      type: String,
      default: 'linear'
    },
    delay: {
      type: String,
      default: '0s'
    }
  },

  data () {
    return {
      activateDraw: false,
      pathLength: 0,
      offset: 0,
      percentage: this.$props.offsetPercent >= 0
    }
  },

  computed: {
    pathLengthPercentage () : number {
      return this.percentage ? this.pathLength - (this.pathLength * (this.offsetPercent / 100)) : 0
    }
  },

  watch: {
    drawPath () {
      this.activateDraw = true

      this.setPathLength()
    }
  },

  mounted () {
    if (!this.static || this.percentage) {
      this.setupPath()
    }
  },

  methods: {
    setupPath () {
      const path = this.$refs.path as SVGGeometryElement

      this.pathLength = path.getTotalLength()
      this.offset = this.drawPath ? this.pathLengthPercentage : this.pathLength

      if (!this.drawPath) {
        this.onTransitionEnd()
      }
    },
    setPathLength () {
      this.offset = this.drawPath ? this.pathLength * 2 : this.pathLength
    },
    setPathLengthPercentage () {
      this.offset = this.drawPath ? this.pathLength * 2 : this.pathLength
    },
    onTransitionEnd () {
      this.$emit('end')
    }
  }
})
