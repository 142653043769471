import { render, staticRenderFns } from "./ComparisonIntro.vue?vue&type=template&id=6cd0ddc1&scoped=true&"
import script from "./ComparisonIntro.vue?vue&type=script&lang=ts&"
export * from "./ComparisonIntro.vue?vue&type=script&lang=ts&"
import style0 from "./ComparisonIntro.vue?vue&type=style&index=0&id=6cd0ddc1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd0ddc1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VFadeTransition,VIcon})
