
























import Vue from 'vue'

import Copyright from '@/views/Copyright.vue'
import Imprint from '@/views/Imprint.vue'
import Privacy from '@/views/Privacy.vue'

export default Vue.extend({
  name: 'Modal',

  components: { Copyright, Imprint, Privacy },

  props: {
    selected: {
      type: String,
      default: ''
    }
  }
})
