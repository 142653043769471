




















































import Vue from 'vue'
import { mapState } from 'vuex'

import { IS_PROD } from '@/utils/env'

export default Vue.extend({
  data () {
    return {
      show: !this.$cookies.get('cookies.required'),
      disableButtons: false,
      valueRequired: true,
      valueFunctional: false
    }
  },

  computed: {
    ...mapState({ booted: '_booted' })
  },

  methods: {
    acceptAll () {
      this.valueFunctional = true
      this.accept()
    },

    accept () {
      this.disableButtons = true
      // Expire after 1 month
      this.$cookies.set('cookies.required', this.valueRequired, 60 * 60 * 24 * 30)
      this.$cookies.set('cookies.functional', this.valueFunctional, 60 * 60 * 24 * 30)

      if (IS_PROD && this.valueFunctional) {
        this.$gtag?.optIn()
      }

      setTimeout(() => {
        this.show = false
      }, 300)
    }
  }
})
