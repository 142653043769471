import Pusher, { AuthorizerCallback } from 'pusher-js'
import type Channel from 'pusher-js/types/src/core/channels/channel'
import Vue from 'vue'

import { axios } from './axios'

declare module 'vue/types/vue' {
  interface Vue {
    $pusher: Pusher;
  }
}

function _authorize (channel: Channel): {
  authorize: (socketId: string, callback: AuthorizerCallback) => void;
} {
  return {
    authorize (socketId: string, callback: AuthorizerCallback) {
      // const channelSuffix = channel.name.startsWith('private-') ? '/private' : ''
      const authData = {
        socket_id: socketId,
        channel_name: channel.name
      }

      axios.post(process.env.VUE_APP_PUSHER_AUTH_ENDPOINT || '', authData).then((res) => {
        callback(null, res.data)
      })
    }
  }
}

const pusher = new Pusher(process.env.VUE_APP_GRAPHQL_KEY || '', {
  cluster: 'mt1',
  httpHost: process.env.VUE_APP_GRAPHQL_WS_HTTP,
  authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
  authorizer: _authorize,
  wsHost: process.env.VUE_APP_GRAPHQL_WS_HTTP,
  wssPort: 443,
  forceTLS: true,
  enableStats: false,
  enabledTransports: ['ws']
})

Vue.use(() => {
  Vue.prototype.$pusher = pusher
})

export default pusher
export type {
  Channel
}
