import Vue from 'vue'

const bus = new Vue({})

class VisibilityListenerCtor {
  init () {
    let visibilityChange = 'visibilitychange'
    let hidden = 'hidden'
    const doc: any = document
    if (typeof doc.msHidden !== 'undefined') {
      hidden = 'msHidden'
      visibilityChange = 'msvisibilitychange'
    } else if (typeof doc.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
    }

    const handleVisibilityChange = () => {
      const value = !doc[hidden]

      bus.$emit('change', value)
    }

    document.addEventListener(visibilityChange, handleVisibilityChange, false)
  }

  on (callback: (value: boolean) => void) {
    bus.$on('change', callback)
  }

  off (callback: (value: boolean) => void) {
    bus.$off('change', callback)
  }
}

export const VisibilityListener = new VisibilityListenerCtor()
