

































































































































import Vue from 'vue'
import Draggable from 'vuedraggable'
import { mapActions } from 'vuex'

import LightSpectrumVisualizer from '@/components/light-spectrum/LightSpectrumVisualizer.vue'
import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import Scala from '@/components/shared/Scala.vue'
import SvgWrapper from '@/components/shared/SvgWrapper.vue'
import { sleep } from '@/utils'
import Sound from '@/utils/sound'
import { ChatDialog, ScalaValue } from '@/utils/types'

import PathDrawer from '../../components/map/PathDrawer.vue'

interface ColorType {
  order: number
  hex: string
  rotation: number
}

const SLIDER_MIN = 5

export default Vue.extend({
  components: {
    LightSpectrumVisualizer,
    Draggable,
    ChatAvatar,
    Scala,
    PathDrawer,
    SvgWrapper
  },

  data () {
    const colors: ColorType[] = [
      { order: 5, hex: '#e1181b', rotation: Math.round(Math.random() * 360) },
      { order: 4, hex: '#f59401', rotation: Math.round(Math.random() * 360) },
      { order: 3, hex: '#fbe115', rotation: Math.round(Math.random() * 360) },
      { order: 2, hex: '#3db915', rotation: Math.round(Math.random() * 360) },
      { order: 1, hex: '#154fa4', rotation: Math.round(Math.random() * 360) },
      { order: 0, hex: '#7007a6', rotation: Math.round(Math.random() * 360) }
    ]

    function isAscending (arr: number[]) {
      return arr.every(function (x, i) {
        return i === 0 || x >= arr[i - 1]
      })
    }

    let sortIndices = Array(6).fill(0).map(Math.random)
    while (isAscending(sortIndices)) {
      sortIndices = sortIndices.sort(() => 0.5 - Math.random())
    }

    return {
      isLandscape: false,
      exerciseHeight: 0,

      showLightBeam: false,

      colors: colors.map((a, idx) => ({ sort: sortIndices[idx], value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map(a => a.value) as ColorType[],

      pathData: [{
        data: 'M39.6,339.6c0-165.7,134.3-300,300-300s300,134.3,300,300'
      }, {
        data: 'M79.6,339.6c0-143.6,116.4-260,260-260s260,116.4,260,260'
      }, {
        data: 'M119.6,339.6c0-121.5,98.5-220,220-220s220,98.5,220,220'
      }, {
        data: 'M159.6,339.6c0-99.4,80.6-180,180-180s180,80.6,180,180'
      }, {
        data: 'M199.6,339.6c0-77.3,62.7-140,140-140s140,62.7,140,140'
      }, {
        data: 'M239.6,339.6c0-55.2,44.8-100,100-100s100,44.8,100,100'
      }].map((path, index) => ({
        data: path.data,
        color: colors[index].hex
      })),

      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },

      showChat: true,
      dialogEntry: 'entry',
      dialogs: {
        entry: {
          key: 'light_spectrum.step_2_1',
          choices: {
            continue: ''
          },
          sprite: '01_03'
        },
        flashlightHint: {
          key: 'light_spectrum.step_2_2',
          choices: {
            continue: ''
          },
          sprite: '01_04'
        },
        prismHint: {
          key: 'light_spectrum.step_2_3',
          choices: {
            continue: ''
          }
        },
        prismSetAndFlashlightActivated: {
          key: 'light_spectrum.step_2_4',
          choices: {
            continue: ''
          },
          sprite: '01_05'
        },
        dragEnd: {
          key: 'light_spectrum.step_2_6',
          choices: {
            continue: (this as any).startSlider
          },
          sprite: '01_06'
        }
      } as Record<string, ChatDialog>,

      taskCompleted: false,

      prismPositioned: false,
      flashlightClicked: false,
      flashlightClickTimeout: null! as number,
      flashlightCount: 0,

      showColorBalls: false,

      hideChat: false,

      showSlider: false,
      sliderMoved: false,
      drawing: false,
      sliderValue: SLIDER_MIN,

      scalaSteps: Array.from(Array(20).keys()).map(step => ({ value: step }))
    }
  },

  computed: {
    sortableComponentData (): any {
      return {
        props: {
          tag: 'div'
        }
      }
    }
  },

  methods: {
    ...mapActions('level', ['completeLevel', 'changeOverlay']),

    onResize () {
      this.isLandscape = window.innerWidth > window.innerHeight
      this.exerciseHeight = 0
      this.readExerciseHeight()
    },

    async readExerciseHeight () {
      const container = this.$refs.visualizerContainer as HTMLElement
      let height = container.getBoundingClientRect().height
      while (!height) {
        await sleep(20)
        height = container.getBoundingClientRect().height
      }
      this.exerciseHeight = height
    },

    onPrismPositioned (value: boolean) {
      Sound.playEffect(value ? 'correct' : 'wrong')

      if (this.prismPositioned) {
        return
      }

      this.killTimeout()

      this.prismPositioned = value

      if (!value) {
        window.setTimeout(() => {
          this.dialogEntry = 'prismHint'
        }, 1000)
      }
    },

    killTimeout () {
      if (this.flashlightClickTimeout) {
        window.clearTimeout(this.flashlightClickTimeout)
      }
    },

    onFlashlightStart () {
      this.killTimeout()
      Sound.playEffect('flashlight')
    },

    onFlashlightEnd () {
      Sound.playEffect('flashlight')

      if (this.flashlightClickTimeout) {
        window.clearTimeout(this.flashlightClickTimeout)
      }

      if (!this.prismPositioned) {
        if (!this.flashlightClicked) {
          this.triggerFlashlightAction('flashlightHint', 1000, () => {
            this.flashlightClicked = true
          })
        } else {
          this.triggerFlashlightAction('prismHint', 3000)
        }
        return
      }

      if (this.prismPositioned && !this.showColorBalls) {
        this.triggerFlashlightAction('prismSetAndFlashlightActivated', 1000, () => {
          this.showColorBalls = true
        })
      }
    },

    triggerFlashlightAction (dialogId: string, delay = 1000, action?: () => void) {
      this.dialogEntry = null!
      this.flashlightClickTimeout = window.setTimeout(() => {
        this.dialogEntry = dialogId
        if (action) {
          action()
        }
      }, delay)
    },

    onSort () {
      Sound.playEffect('bubble')

      this.taskCompleted = this.colors
        .slice(1)
        .every((item, i) => this.colors[i].order <= item.order)

      if (this.taskCompleted) {
        this.dialogEntry = 'dragEnd'
      }
    },

    startSlider () {
      this.hideChat = true

      this.changeOverlay(false)

      this.showSlider = true
      this.sliderMoved = false
    },

    onScala (data: ScalaValue) {
      if (!this.drawing) {
        this.drawing = true
      }

      this.sliderValue = (data.position * (100 - SLIDER_MIN) / 100) + SLIDER_MIN
      this.sliderMoved = true
    },

    changeView () {
      this.$emit('change')
    }
  }
})
