














import Vue from 'vue'

export default Vue.extend({
  name: 'Privacy',

  data () {
    return {
      privacy: '<span class="text-h6 font-weight-bold text-color-primary">1. Datenschutz auf einen Blick</span><br><br>' +
        '<span class="text-h6 font-weight-bold">Allgemeine Hinweise</span></b><br><br>' +
        'Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung. <br>' +
        ' <br><br>' +
        '<span class="text-h6 font-weight-bold">Datenerfassung auf unserer Website</span><br><br>' +
        '<b>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</b><br>' +
        'Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.<br><br>' +
        '<br><b>Wie erfassen wir Ihre Daten?</b><br>' +
        'Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.<br>' +
        'Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten. <br>' +
        '<br><br><b>Wofür nutzen wir Ihre Daten?</b><br>' +
        'Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden. <br>' +
        '<br><br><b>Welche Rechte haben Sie bezüglich Ihrer Daten?</b><br>' +
        'Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. <br>' +
        ' <br>' +
        '<br><b>Analyse-Tools und Tools von Drittanbietern</b><br>' +
        'Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung. <br>' +
        '<br>Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung informieren.<br>' +
        '<br><br><span class="text-h6 font-weight-bold text-color-primary">2. Allgemeine Hinweise und Pflichtinformationen</span><br><br>' +
        '<span class="text-h6 font-weight-bold">Datenschutz</span><br><br>' +
        'Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.<br>' +
        '<br>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.<br>' +
        '<br>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Hinweis zur verantwortlichen Stelle</span><br>' +
        '<br>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br><br>' +
        'Magistrat der Stadt Fulda<br>' +
        'Tourismus und Kongressmanagement<br>' +
        'Bonifatiusplatz 1<br>' +
        'Palais Buttlar<br>' +
        '36037 Fulda<br>' +
        'Telefon: +49 661 / 102 1810, -1816<br>' +
        'E-Mail: <a href="mailto:tourismus@fulda.de">tourismus@fulda.de</a><br><br>' +
        'Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Widerruf Ihrer Einwilligung zur Datenverarbeitung</span><br><br>' +
        'Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Beschwerderecht bei der zuständigen Aufsichtsbehörde</span><br><br>' +
        'Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.">www.bfdi.bund.de</a><br>' +
        '<br><br><span class="text-h6 font-weight-bold">Recht auf Datenübertragbarkeit</span><br><br>' +
        'Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">SSL- bzw. TLS-Verschlüsselung</span><br><br>' +
        'Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.<br>' +
        '<br>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Auskunft, Sperrung, Löschung</span><br><br>' +
        'Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Widerspruch gegen Werbe-Mails</span><br><br>' +
        'Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.<br>' +
        '<br><br><span class="text-h6 font-weight-bold text-color-primary">3. Datenschutzbeauftragter</span><br><br>' +
        '<span class="text-h6 font-weight-bold">Gesetzlich vorgeschriebener Datenschutzbeauftragter</span><br><br>' +
        'Unsere eigenen Datenschutzbeauftragten erreichen Sie wie folgt:<br><br>' +
        'Datenschutzbeauftragte beim Magistrat der Stadt Fulda<br>' +
        'Frau Ulrike Richter<br>' +
        'Schlossstraße 1<br>' +
        '36037 Fulda<br>' +
        'Telefon: +49 661 / 102 1300<br>' +
        'E-Mail: <a href="mailto:datenschutz@fulda.de">datenschutz@fulda.de</a><br>' +
        '<br><br><span class="text-h6 font-weight-bold text-color-primary">4. Datenerfassung auf unserer Website</span><br><br>' +
        '<span class="text-h6 font-weight-bold">Cookies</span><br><br>' +
        'Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.<br>' +
        'Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.<br>' +
        '<br>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.<br>' +
        '<br>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Cookie-Einwilligung</span><br><br>' +
        'Unsere Website nutzt die Cookie-Consent-Technologie von Cookiebot, um Ihre Einwilligung zur Speicherung bestimmter Cookies in Ihrem Browser einzuholen und diese datenschutzkonform zu dokumentieren.<br>' +
        '<br>Wenn Sie unsere Website betreten, wird ein Cookie in Ihrem Browser gespeichert, in dem die von Ihnen erteilten Einwilligungen oder der Widerruf dieser Einwilligungen gespeichert werden.<br>' +
        'Die erfassten Daten werden gespeichert, bis Sie uns zur Löschung auffordern bzw. das Cybot-Cookie selbst löschen oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Aufbewahrungsfristen bleiben unberührt.<br>' +
        '<br>Detailierte Information zu unseren Cookies und die Möglichkeit zur Änderungen Ihrer Einwilligung finden Sie in unseren <a href="https://www.tourismus-fulda.de/datenschutz/cookies.html">Cookie-Informationen.</a><br>' +
        '<br>Der Einsatz der Cookie-Consent-Technologie erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen für den Einsatz von Cookies einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 S. 1 lit. c DSGVO.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Server-Log-Dateien</span><br><br>' +
        'Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:<br>' +
        '<br><ul><li>Browsertyp und Browserversion</li>' +
        '<li>verwendetes Betriebssystem</li>' +
        '<li>Referrer URL</li>' +
        '<li>Hostname des zugreifenden Rechners</li>' +
        '<li>Uhrzeit der Serveranfrage</li>' +
        '<li>IP-Adresse</li></ul><br>' +
        'Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.<br>' +
        '<br>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Anfrage per E-Mail, Telefon oder Telefax</span><br><br>' +
        'Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.<br>' +
        '<br>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und / oder auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen haben.<br>' +
        '<br>Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Kontaktformular</span><br><br>' +
        'Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.<br>' +
        '<br>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.<br>' +
        '<br>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Verarbeiten von Daten (Kunden- und Vertragsdaten)</span><br><br>' +
        'Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.<br>' +
        'Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte</span><br><br>' +
        'Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut.<br>' +
        '<br>Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.<br>' +
        '<br>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Datenweitergabe</span><br><br>' +
        'Wir geben Ihre Daten nur an Dritte weiter, wenn wir hierzu aufgrund des geltenden Rechts berechtigt oder verpflichtet sind. Gleiches gilt, wenn wir Ihre Daten von Dritten erhalten. <br>' +
        '<br>Eine Berechtigung liegt auch dann vor, wenn Dritte die Daten in unserem Auftrag verarbeiten: Wenn wir unsere Geschäftstätigkeiten (z.B. Einholung von Feedback, Betrieb der Website) nicht selbst vornehmen, sondern von anderen Unternehmern vornehmen lassen, und diese Tätigkeiten mit der Verarbeitung Ihrer Daten verbunden ist, haben wir diese Unternehmen zuvor vertraglich verpflichtet, die Daten nur für die uns rechtlich erlaubten Zwecke zu verwenden. Wir sind befugt, diese Unternehmen insoweit zu kontrollieren.<br>' +
        '<br><br><span class="text-h6 font-weight-bold text-color-primary">5. Analyse Tools und Werbung</span><br><br>' +
        '<span class="text-h6 font-weight-bold">Google Analytics</span><br><br>' +
        'Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.<br>' +
        '<br>Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.<br>' +
        '<br>Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.<br>' +
        '<br>Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat. Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a href="https://www.google.com/analytics/terms/de.html">https://www.google.com/analytics/terms/de.html</a> bzw. unter <a href="https://policies.google.com/?hl=de">https://policies.google.com/?hl=de</a><br>' +
        '<br><br><span class="text-h6 font-weight-bold">IP Anonymisierung</span><br><br>' +
        'Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.<br>' +
        '<br><br><span class="text-h6 font-weight-bold">Browser Plugin</span><br><br>' +
        'Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a><br>' +
        '<br><br><span class="text-h6 font-weight-bold">Widerspruch gegen Datenerfassung</span><br><br>' +
        'Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: Google Analytics deaktivieren.<br>' +
        '<br>Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google: <a href="https://support.google.com/analytics/answer/6004245?hl=de">www.support.google.com</a><br>' +
        '<br><br><span class="text-h6 font-weight-bold">Auftragsdatenverarbeitung</span><br><br>' +
        'Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.'
    }
  }
})
