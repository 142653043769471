











































import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  data () {
    return {
      checkbox: false
    }
  },

  computed: {
    introHintHTML (): string {
      return this.$t('intro_hint') as string
    }
  },

  mounted () {
    this.changeBackground('')
    this.changeOverlay(false)
  },

  methods: {
    ...mapActions('level', ['changeBackground', 'changeOverlay']),

    goToWelcome () {
      this.$gtag.event('disclaimer_confirmed')
      this.$router.replace('/willkommen')
    }
  }
})
