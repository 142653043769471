












import Vue, { Component } from 'vue'
import { mapActions } from 'vuex'

import LightSpectrumExercise from '@/views/lightSpectrum/LightSpectrumExercise.vue'
import LightSpectrumIntro from '@/views/lightSpectrum/LightSpectrumIntro.vue'
import LightSpectrumOutro from '@/views/lightSpectrum/LightSpectrumOutro.vue'

export default Vue.extend({
  data () {
    return {
      views: [{
        component: LightSpectrumIntro,
        background: require('@/assets/images/light-spectrum/background.png')
      }, {
        component: LightSpectrumExercise
      }, {
        component: LightSpectrumOutro,
        background: require('@/assets/images/light-spectrum/background_rainbow.png')
      }],
      selected: 0
    }
  },

  computed: {
    selectedView (): Component & { cid: number } {
      return this.views[this.selected].component as any
    }
  },

  watch: {
    selectedView () {
      this.changeBackground(this.views[this.selected].background || '')
    }
  },

  mounted () {
    this.changeBackground(this.views[this.selected].background || '')
  },

  methods: {
    ...mapActions('level', ['completeLevel', 'changeBackground']),
    onChangeView () {
      this.views.length > this.selected + 1 ? this.selected++ : this.completed()
    },
    completed () {
      this.completeLevel(this.$route.meta.level)

      this.$router.replace('/karte')
    }
  }
})
