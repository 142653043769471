



















































import Vue from 'vue'
import { mapState } from 'vuex'

import BackgroundImage from '@/components/shared/BackgroundImage.vue'
import CookieConsent from '@/components/shared/CookieConsent.vue'
import Modal from '@/components/shared/Modal.vue'
import NavPanel from '@/components/shared/NavPanel.vue'
import Preloader from '@/components/shared/Preloader.vue'
import ScreenSizeError from '@/components/shared/ScreenSizeError.vue'

let lastViewportHeight = 0

export default Vue.extend({
  name: 'App',

  components: {
    CookieConsent,
    ScreenSizeError,
    BackgroundImage,
    Modal,
    NavPanel,
    Preloader
  },

  data () {
    return {
      modal: '',
      forceResize: false
    }
  },

  computed: {
    ...mapState('level', {
      overlay: 'overlay',
      background: 'background'
    }),
    activated (): boolean {
      return this.$store.state._activated
    },
    booted (): boolean {
      return this.$store.state._booted
    }
  },

  mounted () {
    window.setInterval(this.onResize, 100)
  },

  methods: {
    onResize () {
      if (!lastViewportHeight) {
        lastViewportHeight = window.innerHeight
        return
      }

      if (lastViewportHeight !== window.innerHeight) {
        lastViewportHeight = window.innerHeight
        this.forceResize = true

        this.$nextTick(() => {
          this.forceResize = false
        })
      }
    }
  }
})
