








































































































import Vue from 'vue'
import { mapActions } from 'vuex'

import StarRating from '@/components/feedback/StarRating.vue'
import TextInput from '@/components/feedback/TextInput.vue'
import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import Question from '@/components/shared/Question.vue'
import Scala from '@/components/shared/Scala.vue'
import { SEND_FEEDBACK } from '@/graphql'
import { FeedbackInput } from '@/graphql/schema'
import type { ChatDialog, QuestionType, ScalaStep } from '@/utils/types'

type TransformerValue = string | number | string[] | number[]
type TransformerFn = (value: TransformerValue) => TransformerValue

export type FeedbackData = {
  type: string,
  key: string,
  value: string | number | string[] | number[] | undefined,
  labelId?: string,
  multiple?: boolean,
  data?: QuestionType,
  transformer?: TransformerFn
}

export default Vue.extend({
  components: { ChatAvatar, Question, StarRating, TextInput, Scala },

  data () {
    const askForMore = Math.random() >= 0.5

    return {
      feedbackSlides: [{
        type: 'rating',
        key: 'feedback.question_1',
        value: undefined
      }, {
        type: 'scala',
        key: 'feedback.question_2',
        value: undefined,
        labelId: 'feedback.age',
        scala: [{
          value: 4,
          label: this.$t('feedback.age_younger', [4])
        }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }, { value: 11 },
          { value: 12 }, { value: 13 }, { value: 14 }, { value: 15 }, { value: 16 }, { value: 17 },
          {
            value: 18,
            label: this.$t('feedback.age_older', [18])
          }]
      }, {
        type: 'question',
        key: 'feedback.question_3',
        value: undefined,
        data: [{
          id: 1,
          label: this.$t('feedback.question_3_answer_1')
        }, {
          id: 2,
          label: this.$t('feedback.question_3_answer_2')
        }, {
          id: 3,
          label: this.$t('feedback.question_3_answer_3')
        }, {
          id: 4,
          label: this.$t('feedback.question_3_answer_4')
        }, {
          id: 5,
          label: this.$t('feedback.question_3_answer_5')
        }]
      }, {
        type: 'rating',
        key: 'feedback.question_4',
        value: undefined
      }, {
        type: 'text',
        key: 'feedback.question_5',
        value: undefined
      }, {
        type: 'text',
        key: 'feedback.question_6',
        value: undefined
      }, {
        type: 'question',
        key: 'feedback.question_7',
        value: undefined,
        multiple: true,
        data: [{
          id: 1,
          label: this.$t('feedback.question_7_answer_1')
        }, {
          id: 2,
          label: this.$t('feedback.question_7_answer_2')
        }, {
          id: 3,
          label: this.$t('feedback.question_7_answer_3')
        }, {
          id: 4,
          label: this.$t('feedback.question_7_answer_4')
        }, {
          id: 5,
          label: this.$t('feedback.question_7_answer_5')
        }, {
          id: 6,
          label: this.$t('feedback.question_7_answer_6')
        }, {
          id: 7,
          label: this.$t('feedback.question_7_answer_7')
        }, {
          id: 8,
          label: this.$t('feedback.question_7_answer_8')
        }]
      }, {
        type: 'text',
        key: 'feedback.question_8',
        value: undefined
      }] as FeedbackData[],

      dialogs: {
        askForMore: {
          key: 'feedback.ask_more',
          choices: {
            yes: (this as any).enableFullFeedback,
            no: (this as any).completed
          }
        }
      } as Record<string, ChatDialog>,
      dialogEntry: '',

      shouldAskForMore: askForMore,
      askForMore,
      showFullFeedback: !askForMore
    }
  },

  computed: {
    feedbackGraphQLData (): FeedbackInput[] {
      const feedbackType: FeedbackInput = {
        key: 'feedback.short',
        type: 'boolean',
        value: JSON.stringify(this.shouldAskForMore)
      }

      const data = this.feedbackSlides.map<FeedbackInput | undefined>(f => {
        if (!f.value) return undefined

        return {
          key: f.key,
          type: f.type,
          value: JSON.stringify(f.value)
        }
      }).filter(Boolean) as FeedbackInput[]

      return [feedbackType, ...data]
    }
  },

  mounted () {
    this.changeBackground('')
    this.changeOverlay(false)

    this.$gtag.screenview({
      screen_name: 'feedback',
      short: this.shouldAskForMore
    } as any)
  },

  methods: {
    ...mapActions('level', [
      'completeFeedback',
      'changeOverlay',
      'changeBackground'
    ]),

    changeSlide () {
      this.completed()
    },

    enableFullFeedback () {
      this.showFullFeedback = true
      this.dialogEntry = ''
    },

    completed () {
      if (this.askForMore) {
        this.askForMore = false
        this.dialogEntry = 'askForMore'
        return
      }

      try {
        this.$apollo.mutate({
          mutation: SEND_FEEDBACK,

          variables: {
            key: process.env.VUE_APP_ID,
            useragent: window.navigator.userAgent,
            data: this.feedbackGraphQLData
          }
        })

        this.$gtag.event('feedback_given', { short: this.shouldAskForMore })
      } catch (_) {

      }

      this.completeFeedback(true)

      this.$router.replace('/abschluss')
    },
    onChange (data: string[] | string | number, index: number, transformer: TransformerFn = (v) => v) {
      this.feedbackSlides[index].value = transformer(data)
    },
    onScalaChange (data: ScalaStep, index: number, transformer: TransformerFn = (v) => v) {
      this.feedbackSlides[index].value = transformer(data.value)
    }
  }
})
