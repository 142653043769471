import type { AxiosInstance } from 'axios'
import _axios from 'axios'
import Vue from 'vue'

declare module 'vue/types/vue' {
  export interface Vue {
    $http: AxiosInstance;
  }
}

export const axios = _axios.create({
  baseURL: process.env.VUE_APP_GRAPHQL_HTTP_ENDPOINT
})

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  const token = '' // store.state.auth.token

  if (token) {
    const headers = config.headers || (config.headers = {})
    headers.authorization = `Bearer ${token}`
  }

  return config
}, function (error) {
  return Promise.reject(error)
})

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  // TODO: Handle 503 (Maintenance Mode) error
  return Promise.reject(error)
})

Vue.use({
  install: function (Vue) {
    Object.defineProperties(Vue.prototype, {
      $http: {
        get () {
          return axios
        }
      }
    })
  }
})

export default axios
