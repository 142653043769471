import { ActionTree } from 'vuex'

/* import { AUTHORIZE_WITH_ACCESS_CODE } from '@/graphql/auth'
import { AccessCodeInput } from '@/graphql/schema'
import { apolloClient } from '@/plugins/apollo' */
import { ACTIONS } from '@/plugins/store/auth/consts'
import { AuthState } from '@/plugins/store/auth/state'

export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [ACTIONS.AUTHORIZE_WITH_ACCESS_CODE]: ({ commit, state }, payload) => {
    return payload === '000000'
    // return apolloClient.mutate({
    //   mutation: AUTHORIZE_WITH_ACCESS_CODE,
    //   variables: {
    //     input: {
    //       code: payload,
    //       tour_id: 1,
    //       uuid: state.uuid
    //     } as AccessCodeInput
    //   }
    // }).then((result) => {
    //   commit(MUTATIONS.SET_ACCESS_TOKEN, result.data.authorizeWithAccessCode.token)
    // })
  }
} as ActionTree<any, AuthState>
