export interface LevelState {
  welcomeCompleted: boolean,
  tourCompleted: boolean,
  current: number,
  completed: number[]
  feedbackGiven: boolean,
  finished: boolean,
  overlay: boolean,
  background: string,
}

const levelState: LevelState = {
  welcomeCompleted: false,
  tourCompleted: false,
  current: 0,
  completed: [],
  finished: false,
  feedbackGiven: false,
  overlay: false,
  background: ''
}

export default levelState
