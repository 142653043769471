


































import Vue from 'vue'

type Attribution = {
  title: string
  author: string
  origin: string
  link: string
}

type License = {
  license: string,
  link: string,
  attributions: Attribution[]
}

export default Vue.extend({
  name: 'Copyright',

  data () {
    return {
      licenses: [{
        license: 'Creative Common BY',
        link: 'https://creativecommons.org/licenses/by/4.0/legalcode.de',
        attributions: [{
          title: 'Cute Owl',
          author: 'Fendah',
          origin: 'LottieFiles',
          link: 'https://lottiefiles.com/33321-cute-owl'
        }, {
          title: 'Tree of life',
          author: 'Paul Woodward',
          origin: 'LottieFiles',
          link: 'https://lottiefiles.com/45390-tree-of-life'
        }]
      }] as License[]
    }
  }
})
