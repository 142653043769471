

































































import Vue from 'vue'
import { mapActions } from 'vuex'

import Compare from '@/components/comparison/Compare.vue'
import type { ScratchEnd } from '@/components/comparison/Scratch.vue'
import Scratch from '@/components/comparison/Scratch.vue'
import ChatAvatar from '@/components/shared/ChatAvatar.vue'
import { ChatDialog } from '@/utils/types'

import beforeImage from '../../assets/images/comparison/before.png'
import todayImage from '../../assets/images/comparison/today.png'

export default Vue.extend({
  components: {
    Scratch,
    Compare,
    ChatAvatar
  },

  data () {
    return {
      beforeImage,
      todayImage,
      drawingStarted: false,
      comparingStarted: false,
      completed: false,
      dialogEntry: 'entry',
      hintTimeout: null as any,
      buttonEnabled: false,
      showText: false
    }
  },

  computed: {
    dialog (): Record<string, ChatDialog> {
      return {
        entry: {
          key: 'comparison.step_3_1',
          choices: {
            continue: 'step3_5'
          }
        },
        hint: {
          key: 'comparison.hint',
          choices: {
            okay: (this as any).startDrawing
          }
        },
        step3_5: {
          key: 'comparison.step_3_5',
          choices: {
            lets_go: (this as any).startDrawing
          }
        },
        step3_6: {
          key: 'comparison.step_3_6',
          choices: {
            lets_go: (this as any).startComparing
          }
        },
        step3_7: {
          key: 'comparison.step_3_7',
          choices: {
            continue: (this as any).changeView
          }
        }
      }
    }
  },

  methods: {
    ...mapActions({
      completedLevel: 'level/completeLevel',
      changeOverlay: 'level/changeOverlay'
    }),
    startDrawing () {
      this.showText = true

      this.drawingStarted = true
    },
    startComparing () {
      this.comparingStarted = true

      this.changeOverlay(false)
    },
    onScratchStart () {
      if (!this.hintTimeout) {
        return
      }

      window.clearTimeout(this.hintTimeout)
      this.hintTimeout = null
    },
    onScratchEnd (data: ScratchEnd) {
      data.completed ? this.onCompleted() : this.showHint()
    },
    showHint () {
      this.dialogEntry = ''

      this.hintTimeout = setTimeout(() => {
        this.dialogEntry = 'hint'

        this.drawingStarted = false
      }, 1500)
    },
    onCompleted () {
      if (!this.completed) {
        clearTimeout(this.hintTimeout)

        this.hintTimeout = null

        this.completed = true

        this.dialogEntry = 'step3_6'
      }
    },
    async onContinue () {
      window.setTimeout(() => {
        this.dialogEntry = 'step3_7'

        this.comparingStarted = false
      }, 500)
    },
    changeView () {
      this.$emit('change')
    }
  }
})
