

































































































































































































































































































import Vue from 'vue'

import TouchMove, { MoveResult } from '@/directives/touch-move'

export default Vue.extend({
  directives: {
    TouchMove
  },

  props: {
    prism: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: undefined
    }
  },

  data () {
    return {
      flashlightOn: false,
      prismPositioned: false,
      prismMoveActive: false,
      prismTargetPosition: null! as { x: number, y: number },
      prismX: window.innerWidth * 0.9,
      prismY: window.innerHeight - 120,
      prismWidth: 0,
      wrapperWidth: 0,
      wrapperHeight: 0
    }
  },

  mounted () {
    this.onResize()
  },

  methods: {
    onResize () {
      this.wrapperWidth = 0
      this.wrapperHeight = 0
      this.$nextTick(this.calculateSize)
    },
    calculateSize () {
      const el = this.$refs.svgWrapper as HTMLElement
      const rect = el.getBoundingClientRect()
      this.wrapperWidth = rect.width
      this.wrapperHeight = rect.height

      this.prismX = this.wrapperWidth - 100
    },
    calculatePrismTargetPosition () {
      const targetEl: HTMLElement = document.querySelector('.prism-outline')!
      const rect = targetEl.getBoundingClientRect()

      this.prismTargetPosition = {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2
      }

      this.prismWidth = rect.width * 0.9
    },
    onFlashlightClick () {
      this.calculatePrismTargetPosition()
      this.$emit('flashlight-start')
      this.flashlightOn = true
    },
    onPrismMoveStart () {
      this.$emit('prism-move-start')
      this.calculatePrismTargetPosition()
      this.prismMoveActive = true
    },
    onPrismMove (data: MoveResult) {
      this.prismX += data.diff.x
      this.prismY += data.diff.y
    },
    onPrismMoveEnd (e: MoveResult) {
      const diffX = Math.abs(this.prismTargetPosition.x - e.current.x)
      const diffY = Math.abs(this.prismTargetPosition.y - e.current.y)
      this.prismMoveActive = false

      this.prismPositioned = diffX <= 60 && diffY <= 60
      this.$emit('prism-positioned', this.prismPositioned)

      this.prismX = this.prismPositioned ? this.prismTargetPosition.x : this.wrapperWidth - 100
      this.prismY = this.prismPositioned ? this.prismTargetPosition.y : window.innerHeight - 120
    },
    onFlashlightEnd () {
      if (!this.flashlightOn) {
        return
      }

      this.flashlightOn = false

      this.$emit('flashlight-end')
    }
  }
})
