export const MUTATIONS = {
  SET_SWIPE_TUTORIAL_COMPLETED: 'setSwipeTutorialCompleted',
  SET_VOICE_OVER_MUTED: 'setVoiceOverMuted',
  TOGGLE_VOICE_OVER_MUTED: 'toggleVoiceOverMuted',
  SET_MS_PER_CHAR: 'setMsPerChar',

  SET_INFO_BUTTON_LEFT: 'setInfoButtonLeft',
  SET_PRELOADER_TEXT: 'setPreloaderText',
  ENABLE_SCREEN_SIZE_WARNING: 'enableScreenSizeWarning',
  DISABLE_SCREEN_SIZE_WARNING: 'disableScreenSizeWarning'
}
