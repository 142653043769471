import Vue from 'vue'

import { hasOwn } from '@/utils'
import { LatLng } from '@/utils/types'

interface GeoRadiusWatcher {
  position: LatLng,
  outgoing: boolean,
  radius: number,
  callback: () => void
}

interface GeoAreaWatcher {
  outgoing: boolean,
  area: LatLng[],
  callback: () => void
}

export class GeoService {
  private _watcherId: number | null = null
  private _lastPosition: GeolocationPosition | null = null
  // public position: GeolocationPosition | null = null

  private _observed = Vue.observable({
    position: null as GeolocationPosition | null
  })

  public get position (): GeolocationPosition | null {
    return this._observed.position
  }

  private _watcher: Set<GeoRadiusWatcher | GeoAreaWatcher> = new Set()

  async checkPermission (): Promise<boolean> {
    if (!navigator.permissions) {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(() => resolve(true), () => resolve(false))
      })
    } else {
      return new Promise((resolve) =>
        navigator.permissions.query({
            name: 'geolocation'
          }).then(permission => {
            // is geolocation granted?
            switch (permission.state) {
              case 'granted':
                resolve(true)
                break
              case 'denied':
                resolve(false)
                break
              case 'prompt':
                navigator.geolocation.getCurrentPosition(() => resolve(true), () => resolve(false))
                break
            }
          }
          )
      )
    }
  }

  startLocationWatcher (): void {
    this._watcherId = navigator.geolocation.watchPosition((pos) => {
      this._lastPosition = this.position
      this._observed.position = pos

      this._evaluateWatcher()
    })

    navigator.geolocation.getCurrentPosition((pos) => {
      this._lastPosition = this.position
      this._observed.position = pos

      this._evaluateWatcher()
    })
  }

  stopLocationWatcher (): void {
    if (!this._watcherId) return
    navigator.geolocation.clearWatch(this._watcherId)
  }

  watchRadiusEnter (position: LatLng, radius: number, callback: () => void): () => void {
    return this._appendWatcher(position, radius, false, callback)
  }

  watchRadiusLeave (position: LatLng, radius: number, callback: () => void): () => void {
    return this._appendWatcher(position, radius, true, callback)
  }

  watchAreaEnter (area: LatLng[], callback: () => void): () => void {
    return this._appendWatcher(null, area, false, callback)
  }

  watchAreaLeave (area: LatLng[], callback: () => void): () => void {
    return this._appendWatcher(null, area, true, callback)
  }

  private _appendWatcher <T extends null | LatLng> (
    position: T,
    radiusOrArea: T extends null ? LatLng[] : number,
    outgoing: boolean,
    callback: () => void
  ) {
    let watcher: GeoRadiusWatcher | GeoAreaWatcher
    if (position !== null) {
      watcher = {
        position: position as LatLng,
        callback,
        outgoing,
        radius: radiusOrArea as number
      }
    } else {
      watcher = {
        callback,
        outgoing,
        area: radiusOrArea as LatLng[]
      }
    }

    this._watcher.add(watcher)

    return () => {
      this._watcher.delete(watcher)
    }
  }

  private _evaluateWatcher () {
    for (const watcher of this._watcher) {
      if (isGeoRadiusWatcher(watcher)) {
        this._evaluateRadiusWatcher(watcher)
      } else {
        this._evaluateAreaWatcher(watcher)
      }
    }
  }

  private _evaluateRadiusWatcher (watcher: GeoRadiusWatcher): void {
    const distance = calculateDistanceInMeters(
      watcher.position.lat,
      watcher.position.lng,
      this.position!.coords.latitude,
      this.position!.coords.longitude
    )

    if (
      (watcher.outgoing && distance > watcher.radius) ||
      (!watcher.outgoing && distance <= watcher.radius)
    ) {
      watcher.callback()
    }
  }

  private _evaluateAreaWatcher (watcher: GeoAreaWatcher): void {
    const inside = isPointInPolygon(
      this.position!.coords.latitude,
      this.position!.coords.longitude,
      watcher.area
    )

    if ((watcher.outgoing && !inside) || (!watcher.outgoing && inside)) {
      watcher.callback()
    }
  }
}

function isGeoRadiusWatcher (v: GeoRadiusWatcher | GeoAreaWatcher): v is GeoRadiusWatcher {
  return hasOwn(v, 'radius')
}

const isPointInPolygon = (lat: number, lng: number, area: LatLng[]) => {
  const verticesY = []
  const verticesX = []
  const longitudeX = lng
  const latitudeY = lat
  let c = false
  let point = 0

  for (let r = 0; r < area.length; r++) {
    verticesY.push(area[r].lat)
    verticesX.push(area[r].lng)
  }
  const numberOfPoints = verticesX.length
  for (let i = 0, j = numberOfPoints; i < numberOfPoints; j = i++) {
    point = i
    if (point === numberOfPoints) { point = 0 }
    if ((
      ((verticesY[point] > latitudeY) !== (verticesY[j] > latitudeY)) &&
      (longitudeX < (verticesX[j] - verticesX[point]) * (latitudeY - verticesY[point]) / (verticesY[j] - verticesY[point]) + verticesX[point]))
    ) {
      c = !c
    }
  }
  return c
}

const calculateDistanceInMeters = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
  if ((lat1 === lat2) && (lng1 === lng2)) {
    return 0
  }

  const radLat1 = Math.PI * lat1 / 180
  const radLat2 = Math.PI * lat2 / 180
  const theta = lng1 - lng2
  const radTheta = Math.PI * theta / 180
  let dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta)
  if (dist > 1) {
    dist = 1
  }
  dist = Math.acos(dist)
  dist = dist * 180 / Math.PI
  dist = dist * 60 * 1.1515

  // Convert to km
  dist = dist * 1.609344

  // Convert to meters
  dist = dist * 1000

  return dist
}

const geoService = new GeoService()

Vue.use(() => {
  Vue.prototype.$geo = geoService
})

declare module 'vue/types/vue' {
  interface Vue {
    $geo: GeoService
  }
}
