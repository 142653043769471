function componentToHex (value: number) {
  const clamp = Math.min(Math.max(value, 0), 255)

  const hex = Math.floor(clamp).toString(16)

  return hex.length === 1 ? '0' + hex : hex
}

export function colorInterpolation (colorOne: string, colorTwo: string, ratio: number): string {
  const r = Math.ceil(parseInt(colorOne.substring(0, 2), 16) * ratio + parseInt(colorTwo.substring(0, 2), 16) * (1 - ratio))
  const g = Math.ceil(parseInt(colorOne.substring(2, 4), 16) * ratio + parseInt(colorTwo.substring(2, 4), 16) * (1 - ratio))
  const b = Math.ceil(parseInt(colorOne.substring(4, 6), 16) * ratio + parseInt(colorTwo.substring(4, 6), 16) * (1 - ratio))

  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}
