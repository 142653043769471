






import Vue from 'vue'

export default Vue.extend({
  mounted () {
    if (this.$slots.default && this.$slots.default.length > 0) {
      const svg = this.$slots.default[0].elm as HTMLElement
      const rect = svg.getBoundingClientRect()

      svg.style.width = rect.width + 'px'
      svg.style.height = rect.height + 'px'
    }
  }
})
