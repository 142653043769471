







































import Vue from 'vue'

import {isTouchEvent, normalizeTouchEvent, sleep} from '@/utils'

export default Vue.extend({
  name: 'Compare',

  props: {
    topImage: {
      type: String,
      required: true
    },
    bottomImage: {
      type: String,
      required: true
    },
    startPosition: {
      type: Number,
      default: 50
    }
  },

  data () {
    return {
      currentPosition: this.$props.startPosition,
      isGrabbing: false,
      elementRect: null! as DOMRect,
      touchIdentifier: 0
    }
  },

  computed: {
    compareContainer (): HTMLDivElement {
      return this.$refs.compare as HTMLDivElement
    }
  },

  mounted () {
    this.getInitialElementRect()
  },

  methods: {
    async getInitialElementRect () {
      this.onResize()

      while (!this.elementRect?.width) {
        await sleep(20)
        this.onResize()
      }
    },

    onResize () {
      this.elementRect = this.$el.getBoundingClientRect()
    },

    onTouchStart (e: TouchEvent | MouseEvent) {
      this.touchIdentifier = isTouchEvent(e) ? e.targetTouches[0].identifier : 0

      e.preventDefault()

      this.isGrabbing = true

      window.addEventListener('touchmove', this.onTouchMove)
      window.addEventListener('mousemove', this.onTouchMove)
      window.addEventListener('touchend', this.onTouchEnd)
      window.addEventListener('mouseup', this.onTouchEnd)
      window.addEventListener('mouseleave', this.onTouchEnd)
    },

    onTouchMove (e: TouchEvent | MouseEvent) {
      const touch = normalizeTouchEvent(e, this.touchIdentifier)
      const rect = this.compareContainer.getBoundingClientRect()
      const position = touch.clientX - rect.left

      this.currentPosition = Math.min(Math.max((position / rect.width) * 100, 0), 100)
    },

    onTouchEnd () {
      this.isGrabbing = false

      window.removeEventListener('touchmove', this.onTouchMove)
      window.removeEventListener('mousemove', this.onTouchMove)
      window.removeEventListener('touchend', this.onTouchEnd)
      window.removeEventListener('mouseup', this.onTouchEnd)
      window.removeEventListener('mouseleave', this.onTouchEnd)

      this.$emit('ended')
    }
  }
})
