import MobileDetect from 'mobile-detect'
const mobileDetect = new MobileDetect(window.navigator.userAgent)

export const IN_BROWSER = typeof window !== 'undefined'
const UA = IN_BROWSER && window.navigator.userAgent.toLowerCase()

export const IS_DEV = process.env.NODE_ENV === 'development'
export const IS_PROD = process.env.NODE_ENV === 'production'

const untypedWindow = window as any

export const IS_TOUCH =
    !!(typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        (untypedWindow.DocumentTouch &&
          typeof document !== 'undefined' &&
          document instanceof untypedWindow.DocumentTouch))) ||
    !!(typeof navigator !== 'undefined' &&
      (navigator.maxTouchPoints || navigator.msMaxTouchPoints))

const IS_IPAD_PRO = (navigator.platform === 'MacIntel' && typeof (navigator as any).standalone !== 'undefined' && IS_TOUCH)
export const IS_IOS = IS_IPAD_PRO || (!!(UA && /iphone|ipad|ipod|ios/.test(UA)) && navigator.vendor.toLowerCase() !== 'google inc.')

export const IS_MOBILE = !!mobileDetect.mobile()
export const IS_TABLET = !!mobileDetect.tablet() ||
  (navigator.platform === 'MacIntel' && typeof (navigator as any).standalone !== 'undefined' && IS_TOUCH)
