import { Module } from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state, { AuthState } from './state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<AuthState, any>
