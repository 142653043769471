/* eslint-disable */
import './styles/app.scss'
import '@mdi/font/css/materialdesignicons.css'
import './services/GeoService'

import Vue from 'vue'
import VueCookie from 'vue-cookies'

import { sleep } from '@/utils'
import { IS_IOS, IS_PROD } from '@/utils/env'
import { panoramaPreload } from '@/utils/panorama'
import Sound from '@/utils/sound'
import { VisibilityListener } from '@/utils/visibility-listener'

import App from './App.vue'
import { apolloProvider } from './plugins/apollo'
import { i18n } from './plugins/i18n'
import router from './plugins/router'
import store from './plugins/store'
import vuetify from './plugins/vuetify'

import './plugins/gtag'
/* eslint-enable */

Vue.config.productionTip = false

Vue.use(VueCookie)

const getTargetRoute = () => {
  let targetRoute = '/karte'

  if (!store.state.level.welcomeCompleted) {
    targetRoute = '/'
  } else if (store.state.level.tourCompleted) {
    targetRoute = '/abschluss'
  }

  return targetRoute
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  apolloProvider,
  async beforeCreate () {
    if (IS_IOS) {
      document.documentElement.classList.add('ios')
    }

    VisibilityListener.init()

    await this.$store.dispatch('initStore')
    await Sound.preload()

    window.setTimeout(() => {
      store.commit('setBooted', true)
        panoramaPreload()
    }, 1000)

    if (IS_PROD && this.$cookies.get('cookies.functional')) {
      this.$gtag?.optIn()
    }

    await this.$store.dispatch('initStore')
    await Sound.preload()
  },
  async mounted () {
    if (this.$router.mode === 'abstract') {
      while (!store.state._booted) {
        await sleep(20)
      }

      const targetPath = getTargetRoute()
      await this.$router.replace(targetPath)

      document.dispatchEvent(new Event('x-app-rendered'))
    }
  },
  render: h => h(App)
}).$mount('#app')
