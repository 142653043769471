








































import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

import {isTouchEvent, normalizeTouchEvent} from '@/utils'
import type { ScalaStep } from '@/utils/types'

export default Vue.extend({
  name: 'Scala',

  props: {
    startPosition: {
      type: Number,
      default: 50
    },
    disabled: {
      type: Boolean,
      default: false
    },
    regularly: {
      type: Boolean,
      default: false
    },
    steps: {
      type: Array as () => ScalaStep[],
      default: () => ([])
    }
  },

  data () {
    return {
      currentPosition: this.$props.startPosition,
      scalaContainer: {} as HTMLDivElement,
      isGrabbing: false,
      touchIdentifier: 0
    }
  },

  computed: {
    computedSteps (): ScalaStep[] {
      const length = this.steps.length - 1

      return this.steps.map((step, index):ScalaStep => {
        return {
          index: index,
          value: step.value,
          ...(step.label && { label: step.label as TranslateResult }),
          position: parseFloat((index / length * 100).toFixed(2))
        }
      })
    },
    currentStep () : ScalaStep | null {
      return !this.computedSteps.length
        ? null
        : this.computedSteps.filter((step) => this.currentPosition === step.position)[0]
    }
  },

  mounted () {
    this.scalaContainer = this.$refs.scala as HTMLDivElement
  },

  methods: {
    onTouchStart (e: MouseEvent | TouchEvent) {
      if (this.disabled) {
        return
      }

      this.touchIdentifier = isTouchEvent(e) ? e.targetTouches[0].identifier : 0

      e.preventDefault()

      this.isGrabbing = true

      window.addEventListener('touchmove', this.onTouchMove)
      window.addEventListener('mousemove', this.onTouchMove)
      window.addEventListener('touchend', this.onTouchEnd)
      window.addEventListener('mouseup', this.onTouchEnd)
      window.addEventListener('mouseleave', this.onTouchEnd)
    },

    onTouchMove (e: TouchEvent | MouseEvent) {
      const touch = normalizeTouchEvent(e, this.touchIdentifier)

      const rect = this.scalaContainer.getBoundingClientRect()
      const position = touch.clientX - rect.left

      const calcPosition = Math.min(Math.max((position / rect.width) * 100, 0), 100)
      const currentPosition = !this.computedSteps.length ? Math.floor(calcPosition) : this.getSnappedPosition(calcPosition)

      this.regularly && currentPosition !== this.currentPosition && this.emitChange(currentPosition)

      this.currentPosition = currentPosition
    },

    getSnappedPosition (position: number): number {
      return this.computedSteps.map((step) => step.position as number).reduce((a, b) => {
        return Math.abs(b - position) < Math.abs(a - position) ? b : a
      })
    },

    emitChange (currentPosition: number) {
      this.$emit('change', {
        index: this.currentStep || 0,
        ...(this.currentStep && { value: this.currentStep.value }),
        ...(this.currentStep && { label: this.currentStep.label }),
        position: currentPosition
      })
    },

    onTouchEnd () {
      this.isGrabbing = false

      !this.regularly && this.emitChange(this.currentPosition)

      window.removeEventListener('touchmove', this.onTouchMove)
      window.removeEventListener('touchend', this.onTouchEnd)
      window.removeEventListener('mousemove', this.onTouchMove)
      window.removeEventListener('mouseup', this.onTouchEnd)
      window.removeEventListener('mouseleave', this.onTouchEnd)
    }
  }
})
