































































































import { Direction, ICard, IStack, Stack, StackConfig } from 'swing'
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'

import LottiePlayer from '@/components/shared/LottiePlayer'
import Sound from '@/utils/sound'

export type SwingCardData = {
  img?: string,
  text?: string,
  fullWidth?: boolean,
  key: string | number,
  correct?: boolean
}

const CONFIDENCE_THRESHOLD = 0.75

let _viewWidth = 0

export default Vue.extend({
  name: 'VueSwing',
  components: { LottiePlayer },
  props: {
    title: {
      type: String,
      default: undefined
    },
    titleId: {
      type: String,
      default: undefined
    },
    items: {
      type: Array as () => SwingCardData[],
      default: () => []
    },
    maxWidth: {
      type: [String, Number],
      default: undefined
    }
  },

  data () {
    return {
      stack: null! as IStack,
      cards: [] as any[],
      observer: null! as MutationObserver,

      showTutorial: this.$store.state.core.swipeTutorialCompleted,

      cardsSwiped: 0,

      highlightCorrect: false,
      highlightWrong: false
    }
  },

  computed: {
    ...mapState('core', ['swipeTutorialCompleted']),
    titleText (): string | undefined {
      return this.titleId ? this.$t(this.titleId) as string : this.title
    },
    tutorialHeight () {
      return document.documentElement.clientHeight * 0.5
    },
    viewWidth (): number {
      if (!_viewWidth) {
        const containerEl = this.$refs.container as HTMLElement
        _viewWidth = containerEl.getBoundingClientRect().width
      }
      return _viewWidth
    }
  },

  mounted () {
    const containerEl = this.$refs.container as HTMLElement

    const stackConfig = {
      allowedDirections: [Direction.LEFT, Direction.RIGHT],
      throwOutDistance: () => window.innerWidth,
      isThrowOut: (x, y, element, throwOutConfidence) => {
        const item = this.getCardElData(element)!
        const movedToCorrect = x > 0

        this.highlightWrong = false
        this.highlightCorrect = false

        if (throwOutConfidence > CONFIDENCE_THRESHOLD) {
          if (this.checkDirectionAndPlaySound(item, movedToCorrect)) {
            return true
          }
        }

        return false
      },
      throwOutConfidence: (xOffset) => {
        const rawConfidence = xOffset / (this.viewWidth * 0.4)
        const confidence = Math.min(Math.abs(rawConfidence), 1)

        this.highlightCorrect = rawConfidence > CONFIDENCE_THRESHOLD
        this.highlightWrong = rawConfidence < -CONFIDENCE_THRESHOLD

        return confidence
      }
    } as StackConfig

    this.stack = Stack(stackConfig)
    const children = Array.prototype.slice.call(containerEl.children)

    children.forEach(el => {
      this.cards.push(this.stack.createCard(el))
    })

    // Observe changes in DOM
    this.observer = new MutationObserver(mutations => {
      const addedElements = [] as Node[]
      const removedElements = [] as Node[]
      mutations.forEach(({ addedNodes, removedNodes }) => {
        addedElements.push(...addedNodes)
        removedElements.push(...removedNodes)
      })

      // Create a new card for each new element
      addedElements.forEach(el => {
        // Ignore if the added element is also removed
        const i = removedElements.indexOf(el)
        if (i !== -1) {
          removedElements.splice(i, 1)
          return
        }

        const card = this.stack.getCard(el as HTMLElement)
        if (card === null) {
          this.cards.push(card)
        }
      })

      // Remove the card if the element is gone
      removedElements.forEach(el => {
        const card = this.stack.getCard(el as HTMLElement)
        if (card != null) {
          this.cards.splice(this.cards.indexOf(card), 1)
          this.stack.destroyCard(card)
        }
      })
    })
    this.observer.observe(containerEl, { childList: true })

    this.stack.on('throwoutend', () => {
      this.cardsSwiped += 1
      this.$emit(
        'update',
        this.cardsSwiped,
        this.cards.length,
        this.cardsSwiped / this.cards.length
      )

      if (this.cardsSwiped >= this.cards.length) {
        this.$emit('completed')
      }
    })

    if (this.titleId) {
      setTimeout(() => {
        Sound.play(this.titleId)
      }, 300)
    }
  },

  beforeDestroy () {
    this.cards.forEach(c => this.stack.destroyCard(c))
    this.observer.disconnect()
  },

  methods: {
    ...mapMutations('core', ['setSwipeTutorialCompleted']),

    checkDirectionAndPlaySound (data: SwingCardData, toCorrect: boolean): boolean {
      const isCorrectDirection = !!data.correct === toCorrect

      Sound.playEffect(isCorrectDirection ? 'correct' : 'wrong')

      return isCorrectDirection
    },

    getCurrentCard (): {
      card: ICard,
      data: SwingCardData,
      el: HTMLElement
    } {
      const containerEl = this.$refs.container as HTMLElement
      const cardEl: HTMLElement =
        containerEl.querySelector(`[data-index="${this.cards.length - this.cardsSwiped - 1}"]`)!

      return {
        card: this.stack.getCard(cardEl),
        data: this.getCardElData(cardEl)!,
        el: cardEl as HTMLElement
      }
    },

    getCardElData (el: HTMLElement): SwingCardData | undefined {
      const index = parseInt(el.dataset.index!)
      return this.items[index]
    },

    swingLeft () {
      const { card, el, data } = this.getCurrentCard()

      if (card && this.checkDirectionAndPlaySound(data, false)) {
        el.classList.add('animate')
        card.throwOut(-this.viewWidth / 4 * 3, 0)
      }
    },
    swingRight () {
      const { card, el, data } = this.getCurrentCard()

      if (card && this.checkDirectionAndPlaySound(data, true)) {
        el.classList.add('animate')
        card.throwOut(this.viewWidth / 4 * 3, 0)
      }
    }
  }
})
